import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Grid2, useTheme } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../hooks/useAuthHeader";

import { RequestCard, RequestMetadata } from "./styled/requests.styled";

export default function ManagerApprovalRequest() {
	const { action } = useParams();
	const { requestId } = useParams();
	let authHeader = useAuthHeader();

	const [approved, setApproved] = useState(false);
	const [rejected, setRejected] = useState(false);
	const [requestError, setRequestError] = useState("");

	const [request, setRequest] = useState({});
	const [rule, setRule] = useState({});

	const theme = useTheme();

	const approveRequest = () => {
		axios.put(apiRoutes.requestUpdate + "/" + requestId + "/approve", {
			headers: authHeader
		})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					setApproved(true);
				} else {
					setRequestError("Error approving request: " + res.data.message);
				}
			}).catch((err) => {
				console.log(err);
				setRequestError("Error approving request: " + err.response.data.message);
			});
	};

	const initRequest = () => {
		axios.get(apiRoutes.getRequest + "/" + requestId, {
			headers: authHeader
		})
			.then((res) => {
				setRequest(res.data);
				setRule(res.data.rule);
				if (action === "approve") {
					approveRequest();
				} else if (action === "reject") {
					rejectRequest();
				}
			}).catch((err) => {
				setRequestError("Error retrieving request: " + err);
				console.log(err);
			});
	};

	const rejectRequest = () => {
		axios.put(apiRoutes.requestUpdate + "/" + requestId + "/reject", {
			headers: authHeader
		})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					setRejected(true);
				} else {
					setRequestError("Error rejecting request");
				}
			}).catch((err) => {
				console.log(err);
				setRequestError("Error rejecting request: " + err.response.data.message);
			});
	};

	useEffect(() => {
		initRequest();
	}, []);

	return (
		<React.Fragment>
			<RequestCard>
				<Grid2 container spacing={1} justifyContent="space-evenly">
					<Grid2 item sx={{marginTop: "25px", paddingTop: "20px"}} xs={10}>
						{process.env.REACT_APP_VERSION ?
							<img href="/rules/library" src="https://coapp-public-assets.s3.amazonaws.com/CoApp-Logo-COLOR-RGB.png" alt="CoApp Logo" style={{ height: "40px", width: "115.62px" }} />
							:
							<img href="/rules/library" src="/images/CoApp-Logo-COLOR-RGB.png" alt="CoApp Logo" style={{ height: "40px", width: "115.62px" }} />
						}
					</Grid2>
					{
						approved  ?
							<Grid2 item xs={10}>
								<CheckCircleOutline sx={{ color: theme.palette.secondary.main, height: "36.67px", width: "36.67px" }} />
								<h4>Request Approved</h4>
							</Grid2>
							: null
					}
					{
						rejected && !requestError ?
							<Grid2 item xs={10}>
								<ErrorOutline sx={{ color: theme.palette.error.main, height: "36.67px", width: "36.67px" }} />
								<h3>Request Rejected</h3>
							</Grid2>
							: null
					}
					{
						requestError ?
							<Grid2 item xs={10}>
								<h3>{requestError}</h3>
							</Grid2>
							: null
					}
					<Grid2 item xs={10}>
						
						{ approved ? (
							<RequestMetadata>{rule.name} Request from {request.requester} on {request.createdAt} successfully approved. </RequestMetadata>
						) : (
							<RequestMetadata>{rule.name} Request from {request.requester} on {request.createdAt} successfully rejected. </RequestMetadata>
						)}
					</Grid2>
					<Grid2 item sx={{marginTop: "20px", padding: "15px"}} xs={10}>
						Request ID: {request.requestCode}
					</Grid2>
				</Grid2>
			</RequestCard>
		</React.Fragment>
	);
}
