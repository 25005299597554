import { useEffect, useRef, useState } from "react";

/**
 * Custom hook for verifying if a resource exists with built-in 
 * rate limiting, timeout handling, and validation
 */
function useResourceVerifier({
	id,                      // Resource ID to check
	validateIdFn,            // Function to validate ID format
	fetchResourceFn,         // Function that returns API call promise
	validateResponseFn,      // Function to validate response
	authHeader,              // Auth header for API calls
	timeoutMs = 5000,
	maxAttempts = 5,
	rateLimitMs = 1000,
	invalidIdMsg = "Invalid ID format",
	timeoutMsg = "Timeout. Resource may not exist or has been removed."
}) {
	const [isLoading, setIsLoading] = useState(true);
	const [resourceError, setResourceError] = useState(null);
	const [resourceData, setResourceData] = useState(null);
	
	// Refs for preventing infinite loops and managing timeouts
	const checkedRef = useRef(false);
	const requestAttempts = useRef(0);
	const lastRequestTime = useRef(0);
	const timeoutRef = useRef(null);

	useEffect(() => {
		// Only run this effect once to prevent infinite loops
		if (checkedRef.current) {
			console.log("Already checked resource, skipping");
			return;
		}

		// Validate ID format first
		if (!id || !validateIdFn(id)) {
			console.log("Invalid ID format:", id);
			setResourceError(new Error(invalidIdMsg));
			setIsLoading(false);
			checkedRef.current = true;
			return;
		}

		// Function to check if resource exists
		const checkResourceExists = async () => {
			console.log("Checking if resource exists, attempt:", requestAttempts.current);
			
			// Enforce rate limiting
			const now = Date.now();
			const timeSinceLastRequest = now - lastRequestTime.current;
			if (timeSinceLastRequest < rateLimitMs) {
				// Wait before trying again
				await new Promise(resolve => setTimeout(resolve, rateLimitMs - timeSinceLastRequest));
			}

			// Update last request time
			lastRequestTime.current = Date.now();
			
			// Increment attempt counter
			requestAttempts.current++;

			try {
				console.log("Making API request");
				const response = await fetchResourceFn();
				
				// Validate response
				if (!validateResponseFn(response)) {
					throw new Error("Resource not found or invalid data received");
				}
				
				console.log("Resource found, setting loading to false");
				setResourceData(response.data);
				setIsLoading(false);
				checkedRef.current = true;
				
				// Clear any pending timeout
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
			} catch (error) {
				console.log("Error fetching resource, attempt", requestAttempts.current, error);
				
				// If we've hit the maximum attempts, show error
				if (requestAttempts.current >= maxAttempts) {
					console.log("Maximum attempts reached, showing error");
					setResourceError(error.response?.status === 404 
						? new Error("Resource not found") 
						: new Error(error.message || "Error loading resource data"));
					setIsLoading(false);
					checkedRef.current = true;
					return;
				}

				// Otherwise, try again after rate limit
				setTimeout(checkResourceExists, rateLimitMs);
			}
		};

		// Set a timeout for the entire operation
		timeoutRef.current = setTimeout(() => {
			console.log("Overall timeout reached");
			// Don't check isLoading directly to avoid stale closures
			// Only set error if we haven't completed processing yet
			if (!checkedRef.current) {
				setResourceError(new Error(timeoutMsg));
				setIsLoading(false);
				checkedRef.current = true;
			}
		}, timeoutMs);

		// Start checking if resource exists if we have auth header
		if (authHeader && Object.keys(authHeader).length > 0) {
			checkResourceExists();
		} else {
			console.log("No auth header available, waiting");
		}

		// Cleanup function
		return () => {
			console.log("Cleaning up resource verifier");
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [id, authHeader, validateIdFn, fetchResourceFn, validateResponseFn, invalidIdMsg, timeoutMsg, maxAttempts, rateLimitMs, timeoutMs]);

	return { isLoading, resourceError, resourceData };
}

export default useResourceVerifier;