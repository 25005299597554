import { Component } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectPermissions } from "../../redux/userSlice";

import ErrorCard from "./ErrorCard";

const ErrorBoundaryWithHooks = (props) => {
	const permissions = useSelector(selectPermissions);
	const navigate = useNavigate();
	
	return <ErrorBoundaryClass {...props} permissions={permissions} navigate={navigate} />;
};

class ErrorBoundaryClass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null
		};
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo
		});
		
		console.error("Error caught by ErrorBoundary:", error, errorInfo);
	}

	render() {
		const { hasError, error } = this.state;
		const { children, fallback, permissions, navigate, parentPath } = this.props;

		if (hasError) {
			if (fallback) {
				return fallback(error, this.resetError);
			}

			// Only render error UI if user has permissions
			if (permissions && permissions.includes("ACCESS_MP")) {
				return (
					<ErrorCard
						title="Something went wrong"
						message={error?.message || "This page could not be loaded correctly."}
						returnPath={parentPath || "/"}
						returnButtonText={parentPath ? "Return to List" : "Go Home"}
					/>
				);
			}
			
			// Fall back to a simple error message if no permissions
			return (
				<div>
					<h2>Error: You don&apos;t have permission to view this content</h2>
				</div>
			);
		}

		return children;
	}

	resetError = () => {
		this.setState({
			hasError: false,
			error: null,
			errorInfo: null
		});
	};
}

export default ErrorBoundaryWithHooks;