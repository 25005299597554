import { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import useAuthHeader from "../../hooks/useAuthHeader";
import useResourceVerifier from "../../hooks/useResourceVerifier";
import { ErrorBoundary, ErrorCard, LoadingCard } from "../errors";
import { validateRuleId } from "../errors/ruleValidation";

import RuleWizard from "./RuleBuilder";

const RuleBuilderWithErrorBoundary = () => {
	const { id } = useParams();
	const authHeader = useAuthHeader();
	const [isLoading, setIsLoading] = useState(true);
	const [ruleError, setRuleError] = useState(null);
	
	// Special case for "new" rules - we skip verification entirely
	useEffect(() => {
		if (id === "new") {
			console.log("Creating new rule, no validation needed");
			setIsLoading(false);
			return;
		}
	}, [id]);
	
	// Only run resource verification if this is not a new rule
	const { 
		isLoading: isVerifying, 
		resourceError: verificationError 
	} = useResourceVerifier({
		id,
		validateIdFn: (ruleId) => {
			// Skip validation for "new" rules
			if (ruleId === "new") return true;
			return validateRuleId(ruleId);
		},
		fetchResourceFn: () => {
			// Skip API call for "new" rules
			if (id === "new") return Promise.resolve({ data: { id: "new" } });
			return axios.get(apiRoutes.getRule(id), { headers: authHeader });
		},
		validateResponseFn: (response) => {
			// Skip validation for "new" rules
			if (id === "new") return true;
			return response && response.data && !response.data.error && response.data.id;
		},
		authHeader,
		invalidIdMsg: "Invalid rule ID format",
		timeoutMsg: "Timeout. Rule may not exist or has been deleted."
	});
	
	// Update component state based on verification results
	useEffect(() => {
		// Only update state if id is not "new" and if there's an actual change
		// This prevents unnecessary re-renders
		if (id !== "new") {
			// Use functional updates to ensure we're working with the latest state
			setIsLoading(prevLoading => {
				return prevLoading !== isVerifying ? isVerifying : prevLoading;
			});
			
			setRuleError(prevError => {
				// Only update if the error changed (comparing by message for simplicity)
				const prevMessage = prevError?.message;
				const newMessage = verificationError?.message;
				return prevMessage !== newMessage ? verificationError : prevError;
			});
		}
	}, [id, isVerifying, verificationError]);

	if (isLoading) {
		console.log("Rendering rule loading UI");
		return (
			<LoadingCard
				title="Loading Rule"
				message="Please wait while we load the rule..."
				subMessage="If this takes more than a few seconds, the rule may not exist."
			/>
		);
	}

	if (ruleError) {
		console.log("Rendering rule error UI:", ruleError.message);
		return (
			<ErrorCard
				title="Rule Error"
				message={ruleError.message}
				returnPath="/rules/library"
				returnButtonText="Return to Rules List"
			/>
		);
	}

	return (
		<ErrorBoundary parentPath="/rules/library">
			<RuleWizard />
		</ErrorBoundary>
	);
};

export default RuleBuilderWithErrorBoundary;