import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ErrorBoundary from "./ErrorBoundary";

const RouteErrorBoundary = ({ 
	children, 
	parentPath, 
	resourceName = "resource",
	validateId = null,
	checkExistence = null
}) => {
	const params = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [idError, setIdError] = useState(null);
	
	// Extract ID param - finds the first ID-like parameter
	const idParam = Object.entries(params).find(([key]) => 
		key.toLowerCase().includes("id"))?.[1];
	
	useEffect(() => {
		if (!validateId && !checkExistence) {
			setIsLoading(false);
			return;
		}
		
		const validateResource = async () => {
			setIsLoading(true);
			
			// Validate ID format if validation function provided
			if (validateId && idParam) {
				try {
					const isValid = validateId(idParam);
					if (!isValid) {
						setIdError(new Error(`Invalid ${resourceName} ID format`));
						setIsLoading(false);
						return;
					}
				} catch (error) {
					setIdError(error);
					setIsLoading(false);
					return;
				}
			}
			
			if (checkExistence && idParam) {
				try {
					const exists = await checkExistence(idParam);
					if (!exists) {
						setIdError(new Error(`${resourceName} not found`));
						setIsLoading(false);
						return;
					}
				} catch (error) {
					setIdError(error);
					setIsLoading(false);
					return;
				}
			}
			
			setIdError(null);
			setIsLoading(false);
		};
		
		validateResource();
	}, [idParam, validateId, checkExistence, resourceName]);
	
	// Custom error fallback for ID validation errors
	const idErrorFallback = (error) => {
		return (
			<ErrorBoundary parentPath={parentPath}>
				<div>Error occurred: {error.message}</div>
			</ErrorBoundary>
		);
	};
	
	if (isLoading) {
		return null;
	}
	
	if (idError) {
		return idErrorFallback(idError);
	}
	
	return (
		<ErrorBoundary parentPath={parentPath}>
			{children}
		</ErrorBoundary>
	);
};

export default RouteErrorBoundary;