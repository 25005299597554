import { useNavigate } from "react-router-dom";
import Warning from "@mui/icons-material/Warning";
import { Card } from "@mui/material";

import { CoAppActionButton, CoAppBasicContainer, CoAppH2 } from "../global/styled/global.styled";

/**
 * Reusable error card component for the entire application
 * 
 * @param {Object} props
 * @param {string} props.title - Error title
 * @param {string} props.message - Error message
 * @param {string} props.returnPath - Path to navigate to when "Return" button is clicked
 * @param {string} props.returnButtonText - Text for the return button
 */
const ErrorCard = ({ 
	title = "Error", 
	message = "An error occurred", 
	returnPath = "/", 
	returnButtonText = "Return"
}) => {
	const navigate = useNavigate();

	return (
		<CoAppBasicContainer>
			<Card
				sx={{
					alignItems: "center",
					border: "none",
					borderRadius: "0px",
					boxShadow: "0px 1.97px 20px -0.99px #00000021",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					height: "497px",
					width: { xxl: "1364px", xl: "1100px", lg: "900px", md: "700px", sm: "90%", xs: "95%" },
					maxWidth: "1364px", 
					marginTop: "50px",
					marginLeft: "auto",
					marginRight: "auto",
					padding: "20px"
				}}
			>
				<Warning sx={{ fontSize: "60px", color: "#D32F2F" }} />
				<CoAppH2 style={{ marginTop: "20px", color: "#D32F2F" }}>{title}</CoAppH2>
				<p style={{ margin: "10px 0", fontSize: "16px" }}>This resource could not be loaded.</p>
				<p style={{ margin: "15px 0", color: "#D32F2F", fontSize: "18px", fontWeight: "bold" }}>
					{message}
				</p>
				<div style={{ display: "flex", gap: "16px", marginTop: "30px" }}>
					<CoAppActionButton 
						onClick={() => window.history.back()}
						sx={{ padding: "8px 20px", fontSize: "16px" }}
					>
						Go Back
					</CoAppActionButton>
					<CoAppActionButton 
						onClick={() => navigate(returnPath)}
						sx={{ padding: "8px 20px", fontSize: "16px" }}
					>
						{returnButtonText}
					</CoAppActionButton>
				</div>
			</Card>
		</CoAppBasicContainer>
	);
};

export default ErrorCard;