import {
	useCallback, useEffect, useMemo,
	useRef, useState
} from "react";
import { useNavigate } from "react-router-dom";
import { FilterAlt } from "@mui/icons-material";
import {
	Autocomplete, Checkbox, Grid2,
	Skeleton, Switch, Typography
} from "@mui/material";
import { GridPagination, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messageLevels from "../../constants/message-levels";
import messages from "../../constants/messages";
import pages from "../../constants/pages";
import { getDataGridFakeData } from "../../helpers/coappDataGridUtils";
import { arrayOperators, handleArrayFiltering, statusOperators } from "../../helpers/datagridFiltering";
import useAuthHeader from "../../hooks/useAuthHeader";
import useCoAppNavigation from "../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../hooks/useDatagridSorting";
import useToastAlert from "../../hooks/useToastAlert";
import CoAppDataGridDateTimeCell from "../global/components/datagrid/CoAppDataGridDateTimeCell";
import CoAppDataGridHeader from "../global/components/datagrid/CoAppDatagridHeader";
import CoAppDataGridListCell from "../global/components/datagrid/CoAppDataGridListCell";
import CoAppDataGridSkeletonCell from "../global/components/datagrid/CoAppDataGridSkeletonCell";
import DataGridCustomNoRowsOverlay from "../global/components/datagrid/DataGridCustomNoRowsOverlay";
import CoAppTextField from "../global/components/inputs/CoAppTextField";
import CoAppBulkApplyModal from "../global/components/modals/CoAppBulkApplyModal";
import CoAppConfirmationModal from "../global/components/modals/CoAppConfirmationModal";
import CoAppDestructiveConfirmationModal from "../global/components/modals/CoAppDestructiveConfirmationModal";
import ThreeDotMenu from "../global/components/ThreeDotMenu";
import { CoAppDataGrid, CoAppDataGridContainer, CoAppDataGridPaginationContainer, CoAppDataGridToolbar } from "../global/styled/global.styled";

import RuleDataGridLongTextCell from "./RuleDataGridLongTextCell";
import RuleDataGridSelectionRow from "./RuleDataGridSelectionRow";
import RuleUndoSnackbar from "./RuleUndoSnackbar";

const datagridInitialState = {
	pagination: {
		paginationModel: { pageSize: 50, page: 0 },
	}
};

const arrayColumns = ["groups", "tags"];
const ruleLibraryColumns = ["actions", "name", "active", "description", "groups", "tags", "createdAt", "updatedAt"];

export default function RuleLibraryDataGrid() {
	const authHeader = useAuthHeader();
	const dummyRows = getDataGridFakeData(ruleLibraryColumns);

	const containerRef = useRef(null);
	const [containerHeight, setContainerHeight] = useState("");
	const { openRuleWizard } = useCoAppNavigation();
	const { handleToastAlert } = useToastAlert();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("ruleLibrary");
	const navigate = useNavigate();
	const [rules, setRules] = useState(dummyRows);
	const [loading, setLoading] = useState(true);
	const [selectionModel, setSelectionModel] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarLevel, setSnackbarLevel] = useState("");
	const [isBulkUndo, setIsBulkUndo] = useState(false);
	const [rulesToUndo, setRulesToUndo] = useState([]);
	const [destructiveModalOpen, setDestructiveModalOpen] = useState(false);
	const [ruleBeingDeleted, setRuleBeingDeleted] = useState({ name: "" });
	const [bulkApplyModalOpen, setBulkApplyModalOpen] = useState(false);
	const [bulkDestructiveModalOpen, setBulkDestructiveModalOpen] = useState(false);
	const [bulkStatusUpdateOpen, setBulkStatusUpdateModalOpen] = useState(false);
	const [isBulkActivating, setIsBulkActivating] = useState(false);
	const [bulkStatusUpdateTitle, setBulkStatusUpdateTitle] = useState("");
	const [bulkApplyAvailableItems, setBulkApplyAvailableItems] = useState([]);
	const [bulkApplyItems, setBulkApplyItems] = useState([]);
	const [bulkApplyItemType, setBulkApplyItemType] = useState("");
	const [bulkApplyRemovedItems, setBulkApplyRemovedItems] = useState([]);
	const [activateIsDisabled, setActivateIsDisabled] = useState(false);
	const [deactivateIsDisabled, setDeactivateIsDisabled] = useState(false);

	const model = bulkApplyItemType.toLowerCase().includes("tag") ? "tags" : "groups";

	const handleStatusToggleChange = (rule) => {
		axios.put(apiRoutes.toggleRuleStatus(rule.id), {}, { headers: authHeader })
			.then(() => {
				initRuleLibrary();
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, rule.active ? messages.RULE_DEACTIVATION_ERROR_MSG : messages.RULE_ACTIVATION_ERROR_MSG);
			});
	};

	const handleAddRuleClick = () => {
		openRuleWizard();
	};

	const handleEditRuleClick = (ruleId) => {
		navigate(pages.editRule(ruleId));
	};

	const handleSoftDeleteRule = () => {
		axios.delete(apiRoutes.deleteRule + "/" + ruleBeingDeleted.id, {
			headers: authHeader
		})
			.then(() => {
				setSnackbarLevel("info");
				setSnackbarMessage(messages.RULE_TRASHED_SUCCESS_MSG);
				setSnackbarOpen(true);
				setIsBulkUndo(false);
				initRuleLibrary();
				setDestructiveModalOpen(false);
			})
			.catch(err => {
				console.error(err);
				handleToastAlert(messageLevels.ERROR, messages.RULE_TRASHED_ERROR_MSG);
			});
	};

	const handleRestoreRule = () => {
		axios.put(apiRoutes.restoreRule(ruleBeingDeleted.id), {}, {
			headers: authHeader
		})
			.then(() => {
				initRuleLibrary();
			})
			.catch(err => {
				console.error(err);
				handleToastAlert(messageLevels.ERROR, messages.RULE_RESTORE_ERROR_MSG);
			});
	};

	const handleBulkRuleRestoration = () => {
		const restorationData = {
			ruleIds: rulesToUndo
		};

		axios.put(apiRoutes.bulkRestoreRules, restorationData, { headers: authHeader })
			.then(() => {
				initRuleLibrary();
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.BULK_RESTORE_FAILED_TOAST_MSG);
			});
	};

	const handleCopyRuleClick = (ruleId) => {
		axios.post(apiRoutes.copyRule(ruleId), {}, {
			headers: authHeader
		})
			.then(() => {
				initRuleLibrary();
			})
			.catch(err => {
				console.error(err);
				handleToastAlert(messageLevels.ERROR, messages.RULE_COPY_ERROR_MSG);
			});
	};

	const columns = [
		{
			field: "name",
			headerName: "Rule name",
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<RuleDataGridLongTextCell value={params.value} hasBeforeEffect={!params.row.active && params.row.isDraft} />
					}
				/>
			)
		},
		{
			field: "active",
			headerName: "Status",
			width: 100,
			align: "center",
			headerAlign: "center",
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<Switch
							checked={Boolean(params.value)}
							disabled={params.row.isDraft}
							onChange={() => handleStatusToggleChange(params.row)}
						/>
					}
				/>
			)
		},
		{
			field: "description",
			headerName: "Description",
			flex: 1,
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<RuleDataGridLongTextCell value={params.value} />
					}
				/>
			)
		},
		{
			field: "groups",
			headerName: "Groups",
			flex: 1,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridListCell items={params.value} resource="Groups" />
					}
				/>
			)
		},
		{
			field: "tags",
			headerName: "Tags",
			flex: 1,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridListCell items={params.value} resource="Tags" />
					}
				/>
			)
		},
		{
			field: "createdAt",
			headerName: "Created",
			width: 175,
			type: "dateTime",
			valueGetter: (params) => !params ? null : new Date(params),
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridDateTimeCell value={params.value} />
					}
				/>
			),
		},
		{
			field: "updatedAt",
			headerName: "Updated",
			width: 175,
			type: "dateTime",
			valueGetter: (params) => !params ? null : new Date(params),
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridDateTimeCell value={params.value} />
					}
				/>
			),
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			sortable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							<ThreeDotMenu
								options={[
									{
										name: "Edit",
										optionClickHandler: () => {
											handleEditRuleClick(params.row.id);
										},
									},
									{
										name: "Duplicate",
										optionClickHandler: () => {
											handleCopyRuleClick(params.row.id);
										},
									},
									{
										name: "Delete",
										optionClickHandler: () => {
											handleDeleteRuleToggle(params.row);
										},
									}
								]}
							/>
						</div>
					}
				/>
			)
		}
	];

	function ArrayTypeInput(props) {
		const { item, applyValue, itemType } = props;
		const [items, setItems] = useState([]);

		useEffect(() => {
			if (itemType === "tags") {
				axios.get(apiRoutes.getTags, { headers: authHeader })
					.then(res => {
						setItems(res.data);
					})
					.catch(err => console.log(err));
			} else {
				axios.get(apiRoutes.getGroups, { headers: authHeader })
					.then(res => {
						setItems(res.data);
					})
					.catch(err => console.log(err));
			}
		}, []);

		const handleSelectionChange = (event, value, reason) => {
			applyValue({ ...item, value: value });
		};

		const handleCheckboxRenderOptions = (props, option, { selected }) => {
			const { key, ...optionProps } = props;
			return (
				<li key={Math.random()} {...optionProps}>
					<Checkbox
						style={{ marginRight: 5 }}
						checked={selected}
						value={option.name}
					/>
					<Typography variant="body2" color="text.primary">{option.name}</Typography>
				</li>
			);
		};

		return (
			<Autocomplete
				multiple
				size="small"
				sx={{ marginTop: "-16px" }}
				limitTags={2}
				options={items}
				getOptionLabel={(option) => option.name}
				value={item.value}
				onChange={handleSelectionChange}
				disableCloseOnSelect
				renderInput={(params) => (
					<CoAppTextField
						{...params}
						label="Filter value"
						placeholder={`Search ${itemType === "tags" ? "tags" : "groups"}`}
						error={props.error}
						helperText={props.helperText}
						FormHelperTextProps={{ style: { color: "#DE3730" } }}
					/>
				)
				}
				renderOption={handleCheckboxRenderOptions}
			/>
		);
	}

	const invisibleFields = ["id"];
	const filterableColumns = useMemo(
		() => columns
			.filter((column) => !invisibleFields.includes(column.field))
			.map((column) => {
				if (arrayColumns.includes(column.field)) {
					return {
						...column,
						getApplyQuickFilterFn: handleArrayFiltering,
						filterOperators: [
							...arrayOperators,
							{
								label: "has any of",
								value: "hasAnyOf",
								getApplyFilterFn: (filterItem) => {
									return (value) => {
										if (!filterItem.value) return null;
										const valueNames = value.map(item => item.name.toLowerCase());
										const filterItemNames = filterItem.value.map(item => item.name.toLowerCase());
										return valueNames.some(name => filterItemNames.includes(name));
									};
								},
								InputComponent: ArrayTypeInput,
								InputComponentProps: {
									itemType: column.field
								}
							}
						]
					};
				} else if (column.field === "active") {
					return {
						...column,
						filterOperators: statusOperators
					};
				} else {
					return column;
				}
			}),
		[columns]);

	const initRuleLibrary = () => {
		axios.get(apiRoutes.getRules, { headers: authHeader })
			.then(res => {
				setRules(res.data.rules);
				setLoading(false);
			})
			.catch(err => console.log(err));
	};

	const CustomToolbar = useCallback(() => {
		return (
			<div style={{ width: "100%" }}>
				<CoAppDataGridToolbar container>
					<Grid2 item xxl={0.1} xl={0.1} lg={0.2} sm={0.2} />
					<Grid2 item xxl={1} xl={1} lg={1.2} sm={1.5}>
						{loading ?
							<Skeleton variant="rectangular" width={100} height={35} sx={{ borderRadius: "10px" }} />
							:
							<GridToolbarFilterButton />
						}
					</Grid2>
					<Grid2 item xxl={4} xl={4} lg={3.5} sm={3}>
						{
							loading ?
								<Skeleton variant="rectangular" width={200} height={35} sx={{ borderRadius: "10px" }} />
								:
								<GridToolbarQuickFilter sx={{ width: { xxl: "220px", xl: "220px", lg: "200px", sm: "150px" } }} />
						}
					</Grid2>
					<CoAppDataGridPaginationContainer item>
						{
							loading ?
								<Skeleton variant="rectangular" width={200} height={35} sx={{ borderRadius: "10px", marginRight: "10px" }} />
								:
								<GridPagination rowsPerPageOptions={[]} />
						}
					</CoAppDataGridPaginationContainer>
					<RuleDataGridSelectionRow
						selectedRules={selectionModel}
						totalRules={rules}
						shouldShow={selectionModel.length > 0}
						onClearSelectionClickHandler={() => setSelectionModel([])}
						onDeactivateClickHandler={() => handleBulkStatusUpdateModalToggle("deactivate")}
						onActivateClickHandler={() => handleBulkStatusUpdateModalToggle("activate")}
						onDeleteClickHandler={handleBulkSoftDeleteRuleToggle}
						onTagClickHandler={() => handleBulkApplyModalToggle("tags")}
						onGroupClickHandler={() => handleBulkApplyModalToggle("groups")}
						activateIsDisabled={activateIsDisabled}
						deactivateIsDisabled={deactivateIsDisabled}
					/>
				</CoAppDataGridToolbar>
			</div>
		);
	}, [selectionModel, loading]);

	const handleBulkApplyModalToggle = (model) => {
		setBulkApplyModalOpen(true);
		setBulkApplyItemType(model === "tags" ? "Tags" : "Groups");
		const apiRoute = model === "tags" ? apiRoutes.getTags : apiRoutes.getGroups;
		axios.get(apiRoute, { headers: authHeader })
			.then(res => {
				setBulkApplyAvailableItems(res.data);
				let bulkItems = [];
				for (let ruleId of selectionModel) {
					let foundRule = rules.find(rule => rule.id === ruleId);
					if (foundRule[model] && foundRule[model].length > 0) {
						bulkItems = [...bulkItems, ...foundRule[model].map(item => { return { name: item.name }; })];
					}
				}
				let justNames = bulkItems.map(item => item.name);
				let uniqueItems = [...new Set(justNames)].map(name => { return { name: name }; });
				setBulkApplyItems([...new Set(uniqueItems)]);
			})
			.catch(err => console.log(err));
	};

	const handleBulkApplyModalClose = () => {
		setBulkApplyModalOpen(false);
		setBulkApplyItems([]);
		setBulkApplyAvailableItems([]);
		setBulkApplyItemType("");
		setBulkApplyRemovedItems([]);
	};

	const handleAddOrRemoveItemChange = (items) => {
		const removedItems = bulkApplyItems.filter(item => !items.includes(item.name));
		if (removedItems.length > 0) {
			setBulkApplyRemovedItems(prev => [...prev, ...removedItems]);
		}
		setBulkApplyItems(items.map(itemName => { return { name: itemName }; }));
	};

	const handleBulkApplyConfirmation = () => {
		const putData = {
			ruleIds: selectionModel,
			itemsToRemove: calculateItemsRemovedFromAll(),
			itemsToAdd: calculateItemsToAddToAll(),
			model: model
		};
		axios.post(apiRoutes.bulkApplyRuleChanges, putData, { headers: authHeader })
			.then(() => {
				initRuleLibrary();
				handleBulkApplyModalClose();
				setSelectionModel([]);
				handleToastAlert(messageLevels.SUCCESS, messages.BULK_APPLY_SUCCESS_TOAST_MSG);
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.BULK_APPLY_FAILED_TOAST_MSG);
			});
	};

	const calculateItemsRemovedFromAll = () => {
		const itemsRemovedFromAll = [];
		for (let item of bulkApplyAvailableItems) {
			const isCurrentlyAppliedToRule = selectionModel.some(ruleId => {
				let foundRule = rules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(item.name);
			});
			const isNotBeingAdded = !bulkApplyItems.map(item => item.name).includes(item.name);
			if ((isCurrentlyAppliedToRule && isNotBeingAdded)) {
				itemsRemovedFromAll.push(item);
			}
		}
		return itemsRemovedFromAll;
	};

	const calculateItemsToAddToAll = () => {
		const itemsToAdd = [];
		for (let item of bulkApplyAvailableItems) {
			const isCurrentlyAppliedToEveryRule = selectionModel.every(ruleId => {
				let foundRule = rules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(item.name);
			});

			const unchangedItems = calculateUnchangedItems();
			const isUnchanged = unchangedItems.map(item => item.name).includes(item.name);
			const isSelected = bulkApplyItems.map(item => item.name).includes(item.name);
			if (!isCurrentlyAppliedToEveryRule && !isUnchanged && isSelected) {
				itemsToAdd.push(item);
			}
		}
		return itemsToAdd;
	};

	const calculateUnchangedItems = () => {
		const unchangedItems = [];
		for (let item of bulkApplyItems) {
			const isCurrentlyAppliedToRule = selectionModel.some(ruleId => {
				let foundRule = rules.find(rule => rule.id === ruleId);
				return foundRule[model].map(item => item.name).includes(item.name);
			});

			const hasBeenRemovedBefore = bulkApplyRemovedItems.filter(removedItem => removedItem.name === item.name).length > 0;
			if (isCurrentlyAppliedToRule && !hasBeenRemovedBefore) {
				unchangedItems.push(item);
			}
		}
		return unchangedItems;
	};

	const handleRowSelectionChange = (newSelectionModel) => {
		if (rules[0]?.isDummyRow) return;
		const fullRules = rules.filter(rule => newSelectionModel.includes(rule.id));
		const hasActiveRules = fullRules.some(rule => rule.active);
		const hasInactiveRules = fullRules.some(rule => !rule.active);

		if (!hasActiveRules) {
			setDeactivateIsDisabled(true);
		} else {
			setDeactivateIsDisabled(false);
		}
		if (!hasInactiveRules) {
			setActivateIsDisabled(true);
		} else {
			setActivateIsDisabled(false);
		}
		setSelectionModel(newSelectionModel);
	};

	const handleDeleteRuleToggle = (rule = { name: "" }) => {
		setDestructiveModalOpen(prev => !prev);
		setRuleBeingDeleted(rule);
	};

	const handleBulkSoftDeleteRuleToggle = () => {
		setBulkDestructiveModalOpen(prev => !prev);
	};

	const handleBulkSoftDeleteRulesConfirmation = () => {
		const deleteData = {
			ruleIds: selectionModel
		};
		axios.delete(apiRoutes.bulkDeleteRules + "?isHardDelete=0", { headers: authHeader, data: deleteData })
			.then(() => {
				setSnackbarMessage(messages.SEND_RULE_TO_TRASH_TOAST_MSG(selectionModel.length > 1));
				setSnackbarLevel("info");
				setSnackbarOpen(true);
				setIsBulkUndo(true);
				setRulesToUndo(selectionModel);
				initRuleLibrary();
				handleBulkSoftDeleteRuleToggle();
				setSelectionModel([]);
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.BULK_SOFT_DELETE_FAILED_TOAST_MSG);
			});
	};

	const handleBulkStatusUpdateConfirmation = () => {
		let newStatus = isBulkActivating ? true : false;

		const putData = {
			ruleIds: getRulesToBeUpdated(),
			newStatus: newStatus
		};

		axios.put(apiRoutes.bulkUpdateRuleStatus, putData, { headers: authHeader })
			.then(() => {
				initRuleLibrary();
				handleBulkStatusUpdateModalToggle();
				setSelectionModel([]);
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.BULK_STATUS_UPDATE_FAILED_TOAST_MSG);
			});
	};

	const getRulesToBeUpdated = () => {
		const objectifiedSelectedRules = selectionModel.map(ruleId => {
			const foundRule = rules.find(totalRule => totalRule.id === ruleId);
			return foundRule;
		});
		let ruleIds = [];
		for (let rule of objectifiedSelectedRules) {
			if (isBulkActivating) {
				if (!rule.active) {
					ruleIds.push(rule.id);
				}
			} else {
				if (rule.active) {
					ruleIds.push(rule.id);
				}
			}
		}
		return ruleIds;
	};

	const getBulkStatusUpdateModalTitle = (isActivating) => {
		const objectifiedSelectedRules = selectionModel.map(ruleId => {
			const foundRule = rules.find(totalRule => totalRule.id === ruleId);
			return foundRule;
		});

		if (isActivating) {
			const toBeActivatedCount = objectifiedSelectedRules.filter(rule => !rule.active).length;
			return `Activate selected inactive rules (${toBeActivatedCount})?`;
		} else {
			const toBeDeactivatedCount = objectifiedSelectedRules.filter(rule => rule.active).length;
			return `Deactivate selected active rules (${toBeDeactivatedCount})?`;
		}
	};

	const handleBulkStatusUpdateModalToggle = (status = "activate") => {
		if (status === "activate") {
			setIsBulkActivating(true);
			setBulkStatusUpdateTitle(getBulkStatusUpdateModalTitle(true));
			setBulkStatusUpdateModalOpen(prev => !prev);
		} else {
			setIsBulkActivating(false);
			setBulkStatusUpdateTitle(getBulkStatusUpdateModalTitle(false));
			setBulkStatusUpdateModalOpen(prev => !prev);
		}
	};

	const handleContainerHeightChange = () => {
		if (containerRef.current) {
			const yPosition = containerRef.current.getBoundingClientRect().y;
			const windowHeight = window.innerHeight;
			const appliedPadding = 20;
			setContainerHeight(`${windowHeight - yPosition - appliedPadding}px`);
		} else {
			setContainerHeight("");
		}
	};

	useEffect(() => {
		initRuleLibrary();
		handleInitializeSortModel();
		handleContainerHeightChange();
		window.addEventListener("resize", handleContainerHeightChange);

		return () => window.removeEventListener("resize", handleContainerHeightChange);
	}, [containerRef.current, containerHeight]);



	return (
		<>
			<CoAppDataGridHeader
				title="Rules"
				resourceType="Rule"
				addIsPresent={true}
				addOnClickHandler={handleAddRuleClick}
				editIsPresent={false}
				deleteIsPresent={false}
				tagManagementIsPresent={true}
				initRuleLibrary={initRuleLibrary}
			/>
			<CoAppDataGridContainer ref={containerRef} height={containerHeight}>

				<CoAppDataGrid
					rows={rules}
					columns={filterableColumns}
					checkboxSelection
					pagination
					disableRowSelectionOnClick
					initialState={{
						...datagridInitialState,
						pinnedColumns: { left: ["actions", "__check__", "name"] }
					}}
					rowSelectionModel={selectionModel}
					onRowSelectionModelChange={handleRowSelectionChange}
					slots={{
						toolbar: CustomToolbar,
						noResultsOverlay: DataGridCustomNoRowsOverlay,
						noRowsOverlay: DataGridCustomNoRowsOverlay,
						openFilterButtonIcon: FilterAlt
					}}
					slotProps={{
						noResultsOverlay: { message: "No rules found." },
						noRowsOverlay: { message: "No rules found." },
						filterPanel: {
							filterFormProps: {
								logicOperatorInputProps: {
									size: "small",
								},
								columnInputProps: {
									size: "small",
								},
								operatorInputProps: {
									size: "small",
								},
								valueInputProps: {
									InputComponentProps: {
										size: "small",
									},
								},
							},
							sx: {
								"& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
								"& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
								"& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
								"& .MuiDataGrid-filterFormValueInput": { width: 200 }
							},
						},
					}}
					hideFooter
					disableColumnMenu
					sortModel={sortModel}
					onSortModelChange={handleSortModelChange}
				/>
			</CoAppDataGridContainer>
			<CoAppBulkApplyModal
				dialogOpen={bulkApplyModalOpen}
				dialogTitle={`Add/remove ${bulkApplyItemType.toLowerCase().includes("group") ? "groups" : "tags"} for selected rules (${selectionModel.length})`}
				cancelClickHandler={handleBulkApplyModalClose}
				confirmClickhandler={handleBulkApplyConfirmation}
				availableItems={bulkApplyAvailableItems}
				items={bulkApplyItems}
				selectedItemChangeHandler={handleAddOrRemoveItemChange}
				resource={bulkApplyItemType}
				targetResource="rules"
				selectedRules={selectionModel}
				totalRules={rules}
				itemsRemoved={bulkApplyRemovedItems}
				calculateItemsRemovedFromAll={calculateItemsRemovedFromAll}
				calculateItemsToAddToAll={calculateItemsToAddToAll}
				calculateUnchangedItems={calculateUnchangedItems}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={destructiveModalOpen}
				dialogTitle={`Send ${ruleBeingDeleted.name} rule to the trash?`}
				dialogMessage="Are you sure you would like to send this rule within your organization to the trash? This can be restored later."
				confirmClickHandler={handleSoftDeleteRule}
				cancelClickHandler={handleDeleteRuleToggle}
				actionText="Send to trash"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkDestructiveModalOpen}
				dialogTitle={`Send selected (${selectionModel.length}) rules to the trash?`}
				dialogMessage="Are you sure you would like to send the selected rules within your organization to the trash? These can be restored later."
				confirmClickHandler={handleBulkSoftDeleteRulesConfirmation}
				cancelClickHandler={handleBulkSoftDeleteRuleToggle}
				actionText="Send to trash"
			/>
			<CoAppConfirmationModal
				dialogOpen={bulkStatusUpdateOpen}
				dialogTitle={bulkStatusUpdateTitle}
				dialogMessage={`Are you sure you would like to ${isBulkActivating ? "activate" : "deactivate"} the selected ${isBulkActivating ? "inactive" : "active"} rules within your organization?`}
				confirmClickHandler={handleBulkStatusUpdateConfirmation}
				cancelClickHandler={handleBulkStatusUpdateModalToggle}
				actionText={isBulkActivating ? "Activate" : "Deactivate"}
			/>
			<RuleUndoSnackbar
				level={snackbarLevel}
				message={snackbarMessage}
				open={snackbarOpen}
				setLevel={setSnackbarLevel}
				setMessage={setSnackbarMessage}
				setOpen={setSnackbarOpen}
				undoHandler={isBulkUndo ? handleBulkRuleRestoration : handleRestoreRule}
			/>
		</>
	);
}