import {
	DialogActions, DialogContent, DialogContentText,
	DialogTitle, Table, TableBody,
	TableContainer, TableHead, TableRow
} from "@mui/material";

import {
	CoAppBasicTableCell, CoAppBasicTableContainer, CoAppBasicTableRow,
	CoAppCancelTextButton, CoAppCloseIcon, CoAppDestructiveActionButton,
	CoAppDialog, CoAppDialogTitleIconAndTextContainer, CoAppWarningIcon
} from "../../styled/global.styled";

export default function CoAppKillswitchModal(props) {
	const {
		dialogOpen, dialogTitle, dialogMessage,
		confirmClickHandler, cancelClickHandler, actionText,
		isOrganizationWide = true, workstations = []
	} = props;

	if (isOrganizationWide) {
		return (
			<CoAppDialog
				open={dialogOpen}
				onClose={cancelClickHandler}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
							padding: "8px"
						}
					}
				}}
			>
				<CoAppDialogTitleIconAndTextContainer>
					<CoAppWarningIcon withoutscroll={"true"} />
					<DialogTitle variant="h6">
						{dialogTitle}
					</DialogTitle>
				</CoAppDialogTitleIconAndTextContainer>
				<CoAppCloseIcon onClick={cancelClickHandler} />
				<DialogContent>
					<DialogContentText variant="body1" color="textPrimary">{dialogMessage}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<CoAppCancelTextButton onClick={cancelClickHandler} color="primary">
						Cancel
					</CoAppCancelTextButton>
					<CoAppDestructiveActionButton onClick={confirmClickHandler} color="primary" autoFocus>
						{actionText}
					</CoAppDestructiveActionButton>
				</DialogActions>
			</ CoAppDialog>
		);
	} else {
		return (
			<CoAppDialog
				open={dialogOpen}
				onClose={cancelClickHandler}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
							padding: "15px"
						}
					}
				}}
			>
				<CoAppDialogTitleIconAndTextContainer>
					<CoAppWarningIcon withoutscroll={(workstations.length <= 10).toString()} />
					<DialogTitle variant="h6" sx={{ width: "500px" }}>
						{dialogTitle}
					</DialogTitle>
				</CoAppDialogTitleIconAndTextContainer>
				<CoAppCloseIcon onClick={cancelClickHandler} />
				<DialogContentText variant="body1" color="textPrimary" sx={{ marginLeft: "55px" }}>{dialogMessage}</DialogContentText>
				<DialogContent sx={{ padding: 0, overflow: "hidden" }}>
					<TableContainer sx={{ borderBottom: workstations.length > 1 ? "3px solid #0000001F" : "", }}>
						<Table>
							<TableHead>
								<TableRow>
									<CoAppBasicTableCell align="left">Serial #</CoAppBasicTableCell>
									<CoAppBasicTableCell align="center">Computer</CoAppBasicTableCell>
									<CoAppBasicTableCell align="center">Internal IP</CoAppBasicTableCell>
									<CoAppBasicTableCell align="left">Mac Address</CoAppBasicTableCell>
								</TableRow>
							</TableHead>
						</Table>
					</TableContainer>
					<CoAppBasicTableContainer withoutscroll={(workstations.length <= 10).toString()}>
						<Table>
							<TableBody>
								{
									workstations ?
										workstations.map((workstation, index) => (
											(
												<CoAppBasicTableRow key={index} withoutscroll={(workstations.length <= 10).toString()} data-testid="table-data-row">
													<CoAppBasicTableCell align="left">{workstation.serialNumber}</CoAppBasicTableCell>
													<CoAppBasicTableCell align={workstations.length >= 10 ? "center" : "left"}>{workstation.hostName}</CoAppBasicTableCell>
													<CoAppBasicTableCell align={workstations.length >= 10 ? "center" : "left"}>{workstation.ip}</CoAppBasicTableCell>
													<CoAppBasicTableCell align={workstations.length >= 10 ? "center" : "left"}>{workstation.macAddress}</CoAppBasicTableCell>
												</CoAppBasicTableRow>
											)
										))
										:
										null
								}
							</TableBody>
						</Table>
					</CoAppBasicTableContainer>
				</DialogContent>
				<DialogActions sx={{ marginTop: "10px", marginRight: "15px" }}>
					<CoAppCancelTextButton onClick={cancelClickHandler} color="primary">
						Cancel
					</CoAppCancelTextButton>
					<CoAppDestructiveActionButton onClick={confirmClickHandler} color="primary" autoFocus>
						{actionText}
					</CoAppDestructiveActionButton>
				</DialogActions>
			</CoAppDialog >
		);
	}
}