import React from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useResourceVerifier from "../../../hooks/useResourceVerifier";
import { ErrorBoundary, ErrorCard, groupValidation, LoadingCard } from "../../errors";

const GroupWithErrorBoundary = ({ children }) => {
	const { groupId } = useParams();
	const location = useLocation();
	const authHeader = useAuthHeader();

	const { 
		isLoading, 
		resourceError: groupError, 
		resourceData: groupData 
	} = useResourceVerifier({
		id: groupId,
		validateIdFn: groupValidation.validateGroupId,
		fetchResourceFn: () => axios.get(
			apiRoutes.getGroupById(groupId),
			{ headers: authHeader }
		),
		validateResponseFn: (response) => response && response.data && !response.data.error,
		authHeader,
		invalidIdMsg: "Invalid group ID format",
		timeoutMsg: "Timeout. Group may not exist or has been removed."
	});

	// Detect if we have a valid state for rendering children
	const canRenderChildren = !isLoading && !groupError && groupData && 
		location.state && typeof location.state === "object";

	// If we don't have state but need it, construct a basic one for the child components
	const getStateForChildren = () => {
		if (!location.state && groupData) {
			return {
				name: groupData.name || `Group ${groupId}`,
				id: groupId
			};
		}
		return location.state;
	};

	if (isLoading) {
		return (
			<LoadingCard
				title="Loading Group"
				message="Please wait while we load the group..."
				subMessage="If this takes more than a few seconds, the group may not exist."
			/>
		);
	}

	if (groupError) {
		return (
			<ErrorCard 
				title="Group Not Found"
				message={groupError.message}
				returnPath="/settings/groups"
				returnButtonText="Return to Groups"
			/>
		);
	}

	return (
		<ErrorBoundary parentPath="/settings/groups">
			{React.cloneElement(children, { 
				...children.props,
				...(canRenderChildren ? {} : { state: getStateForChildren() })
			})}
		</ErrorBoundary>
	);
};

export default GroupWithErrorBoundary;