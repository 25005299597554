import { Add, Delete, Edit } from "@mui/icons-material";
import { FormControlLabel, Grid2, Stack, Switch, Typography } from "@mui/material";

import TagManagement from "../../../tags/TagManagement";
import { CoAppDataGridHeaderTitle } from "../../styled/global.styled";
import CoAppIconActionButton from "../inputs/CoAppIconActionButton";
import CoAppIconTextButton from "../inputs/CoAppIconTextButton";

export default function CoAppDatagridHeader(props) {
	const {
		title, resourceType, addOnClickHandler,
		editOnClickHandler, deleteOnClickHandler, addIsPresent,
		editIsPresent, deleteIsPresent, subResourceType,
		hasDescription = false, description = "", tagManagementIsPresent = false,
		toggleIsPresent = false, toggleHandler = "", toggleStatus = false, initRuleLibrary
	} = props;

	return (
		<Grid2 container justifyContent="space-between" sx={{ borderBottom: "1px solid lightgrey", padding: "20px" }}>
			<Grid2 item xxl={9.5} xl={9} lg={8} md={7} sm={5}>
				<Stack direction="row" spacing={3} alignItems="center">
					<CoAppDataGridHeaderTitle
						variant="h1"
						color="text.primary"
						title={title}
					>
						{title}
					</CoAppDataGridHeaderTitle>
					{
						addIsPresent ?
							<div>
								<CoAppIconActionButton icon={<Add sx={{ fontSize: "16px", marginRight: "5px" }} />} text={`${resourceType === "Rule" ? "Build" : "Add"} ${subResourceType ? subResourceType : resourceType}`} onClick={addOnClickHandler} />
							</div>
							:
							null
					}
					{
						tagManagementIsPresent ?
							<TagManagement initRuleLibrary={initRuleLibrary} />
							:
							null
					}
				</Stack>
				{
					hasDescription ? <Typography sx={{ marginTop: "18px" }} variant="body1" color="text.primary">{description}</Typography> : null
				}
			</Grid2>
			<Grid2 item xxl={2.5} xl={3} lg={3.5} md={5} sm={5}>
				{
					editIsPresent ? <CoAppIconTextButton icon={<Edit sx={{ fontSize: "16px", marginRight: "5px" }} />} text={`Edit ${resourceType} name`} onClick={editOnClickHandler} /> : null
				}
				{
					deleteIsPresent ? <CoAppIconTextButton icon={<Delete sx={{ fontSize: "16px", marginRight: "5px" }} />} text={`Delete ${resourceType}`} onClick={deleteOnClickHandler} isDestructive={true} /> : null
				}
				{
					toggleIsPresent ? 
						<FormControlLabel
							control={
								<Switch
									checked={toggleStatus}
									onChange={toggleHandler}
								/>
							}
							label={<Typography variant="body3" sx={{ fontSize: "12px", pt: "2px", pl: "2px" }}>{toggleStatus ? "Active" : "Inactive"}</Typography>}
							labelPlacement="start"
						/> : null
				}
			</Grid2>
		</Grid2>
	);
}