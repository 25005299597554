import { WHITESPACE_MARKER_STYLE } from "../components/rule-wizard/styled/formula-card.styled";

export default function formatTriggerName(name) {
	if (!/^\s+|\s+$/.test(name)) return name;

	if (!name.trim()) {
		const spaceCount = name.length;
		const result = [];

		for (let i = 0; i < spaceCount; i++) {
			result.push(<span key={i} style={WHITESPACE_MARKER_STYLE}></span>);
		}
		
		return result;
	}

	const trimmedName = name.trim();

	const leadingSpaces = name.match(/^\s+/);
	const trailingSpaces = name.match(/\s+$/);

	const result = [];

	if (leadingSpaces) {
		const leadingSpaceCount = leadingSpaces[0].length;
		for (let i = 0; i < leadingSpaceCount; i++) {
			result.push(<span key={`leading-${i}`} style={WHITESPACE_MARKER_STYLE}></span>);
		}
	}

	result.push(trimmedName);

	if (trailingSpaces) {
		const trailingSpaceCount = trailingSpaces[0].length;
		for (let i = 0; i < trailingSpaceCount; i++) {
			result.push(<span key={`trailing-${i}`} style={WHITESPACE_MARKER_STYLE}></span>);
		}
	}
	
	return result;
}