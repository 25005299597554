export const isNumericId = (id) => {
	if (isNaN(id)) {
		return false;
	}

	return Number(id) > 0 && Number.isInteger(Number(id));
};

export const isValidUuid = (id) => {
	const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return uuidRegex.test(id);
};

export const createPatternValidator = (pattern) => {
	return (id) => pattern.test(id);
};

export const createResourceExistenceChecker = (apiCall) => {
	return async (id) => {
		try {
			const response = await apiCall(id);
			return !!response && !response.error;
		} catch (error) {
			return false;
		}
	};
};

const idValidationUtils = {
	isNumericId,
	isValidUuid,
	createPatternValidator,
	createResourceExistenceChecker
};

export default idValidationUtils;