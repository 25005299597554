import { isNumericId } from "./idValidation";

export const validateUserId = (id) => {
	return isNumericId(id);
};

const userValidation = {
	validateUserId
};

export default userValidation;