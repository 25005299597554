import { DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton, CoAppCloseIcon, CoAppDialog } from "../../styled/global.styled";
import CoAppTextField from "../inputs/CoAppTextField";

export default function CoAppEditNameModal(props) {
	const {
		dialogOpen, dialogTitle, changeHandler,
		confirmClickHandler, cancelClickHandler, actionText,
		placeholderText, editNameError
	} = props;
	return (
		<CoAppDialog
			open={dialogOpen}
			onClose={cancelClickHandler}
			slotProps={{
				paper: {
					sx: {
						borderRadius: "8px",
						padding: "8px",
						width: "400px"
					}
				}
			}}
		>
			<div>
				<DialogTitle variant="h6">{dialogTitle}</DialogTitle>
				<CoAppCloseIcon onClick={cancelClickHandler} top={10} left={365} />
			</div>
			<DialogContent>
				<CoAppTextField
					onChange={changeHandler}
					placeholder={placeholderText}
					fullWidth={true}
					error={editNameError !== ""}
					helperText={editNameError}
				/>
			</DialogContent>
			<DialogActions sx={{ marginBottom: "15px", marginRight: "12px" }}>
				<CoAppCancelTextButton onClick={cancelClickHandler}>Cancel</CoAppCancelTextButton>
				<CoAppActionButton onClick={confirmClickHandler}>{actionText}</CoAppActionButton>
			</DialogActions>
		</CoAppDialog>
	);
}