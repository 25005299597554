import { useState } from "react";
import {
	CircularProgress, Dialog, DialogActions,
	DialogContent, DialogTitle
} from "@mui/material";

import { CoAppActionButton, CoAppCloseIcon, CoAppPlayArrow } from "../../../global/styled/global.styled";

import {
	CoAppEmbeddedVideoContainer, CoAppEmbeddedVideoIframe, CoAppEmbeddedVideoLoadingContainer,
	ScimWizardMailToLink, ScimWizardTryingAgain, ScimWizardVideoOverlayText
} from "./styled/scim-wizard.styled";
import ssoScimVideoLinks from "./ssoScimVideoLinks";

export default function ScimWizardGroupDetectionModal(props) {
	const {
		dialogOpen, closeClickHandler, confirmClickHandler,
		tryAgainClickHandler, identityProviderName, groupsDetected,
		tryAgain, setTryAgain
	} = props;

	const [showVideo, setShowVideo] = useState(false);

	const handleShowVideo = () => {
		setShowVideo(true);
	};

	const handleTryAgain = () => {
		setTryAgain(true);
		tryAgainClickHandler();
	};

	if (tryAgain && !groupsDetected) {
		return (
			<Dialog
				open={dialogOpen}
				onClose={closeClickHandler}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
							padding: "8px"
						}
					}
				}}
				fullWidth={true}
			>
				<DialogTitle variant="h3" color="text.primary" sx={{ marginTop: "20px" }}>
					Trying again...
					<CoAppCloseIcon onClick={closeClickHandler} />
				</DialogTitle>
				<DialogContent sx={{ marginTop: "20px" }}>
					CoApp is trying to verify that groups have been provisioned in {identityProviderName}.
				</DialogContent>
				<DialogActions sx={{ paddingRight: "25px", paddingBottom: "25px" }}>
					<ScimWizardMailToLink href="mailto:support@coapp.com">Email CoApp Support</ScimWizardMailToLink>
					<ScimWizardTryingAgain><CircularProgress size="18px" sx={{ color: "#00000061" }} /></ScimWizardTryingAgain>
				</DialogActions>
			</Dialog>
		);
	} else if (groupsDetected) {
		return (
			<Dialog
				open={dialogOpen}
				onClose={closeClickHandler}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
							padding: "8px"
						}
					}
				}}
				fullWidth={true}
			>
				<DialogTitle variant="h3" color="text.success" sx={{ marginTop: "20px" }}>
					Success!
					<CoAppCloseIcon onClick={closeClickHandler} />
				</DialogTitle>
				<DialogContent sx={{ marginTop: "20px" }}>
					CoApp found provisioned groups from {identityProviderName}.
				</DialogContent>
				<DialogActions sx={{ paddingBottom: "25px", paddingRight: "25px" }}>
					<CoAppActionButton onClick={confirmClickHandler}>Save SCIM Configuration</CoAppActionButton>
				</DialogActions>
			</Dialog>
		);
	} else {
		return (
			<Dialog
				open={dialogOpen}
				onClose={closeClickHandler}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
							padding: "8px",
							height: "500px",
							width: "700px"
						}
					}
				}}
			>
				<DialogTitle variant="h3" color="text.primary">
					No provisioned groups found
					<CoAppCloseIcon onClick={closeClickHandler} />
				</DialogTitle>
				<DialogContent sx={{ marginTop: "25px" }}>
					CoApp is not detecting any provisioned groups from {identityProviderName}.
					<div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: "50px" }}>
						<div style={{ position: "relative" }}>
							<ScimWizardVideoOverlayText color="info.main" variant="body1" >How to provision groups in {identityProviderName}</ScimWizardVideoOverlayText>
							<CoAppPlayArrow onClick={handleShowVideo} top={57} left={145} />
							<img style={{ width: "350px", boxShadow: "0px 3px 6px 1px rgba(0, 0, 0, 0.25)", borderRadius: "12px" }} src={ssoScimVideoLinks.provisionGroupWithMembersThumbnail} alt="Provision Groups with Members Video Thumbnail" />
						</div>
					</div>
					<Dialog open={showVideo} onClose={() => setShowVideo(false)} fullWidth={true} maxWidth="1000px">
						<CoAppEmbeddedVideoContainer>
							<CoAppEmbeddedVideoLoadingContainer>
								<CircularProgress />
							</CoAppEmbeddedVideoLoadingContainer>
							<CoAppEmbeddedVideoIframe
								title="Provision Groups with Members Video"
								src={ssoScimVideoLinks.provisionGroupWithMembersVideo}
								webkitallowfullscreen
								mozallowfullscreen
								allowfullscreen
							/>
						</CoAppEmbeddedVideoContainer>
					</Dialog>
				</DialogContent>
				<DialogActions sx={{ paddingRight: "25px", paddingBottom: "25px" }}>
					<ScimWizardMailToLink href="mailto:support@coapp.com">Email CoApp Support</ScimWizardMailToLink>
					<CoAppActionButton onClick={handleTryAgain}>Try again</CoAppActionButton>
				</DialogActions>
			</Dialog>
		);
	}
}