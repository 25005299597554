import { Component } from "react";
import { Box, Button, Typography } from "@mui/material";

class RootErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null
		};
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo
		});
		
		console.error("Critical application error caught by RootErrorBoundary:", error, errorInfo);
	}

	render() {
		const { hasError, error } = this.state;
		const { children } = this.props;

		if (hasError) {
			return (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
						p: 3,
						textAlign: "center"
					}}
				>
					<Typography variant="h4" color="error" gutterBottom>
						Application Error
					</Typography>
					<Typography variant="body1" gutterBottom>
						A critical error has occurred in the application. Please reload the page or contact support if the problem persists.
					</Typography>
					{error && (
						<Box 
							sx={{ 
								my: 2, 
								p: 2, 
								bgcolor: "rgba(0, 0, 0, 0.05)", 
								borderRadius: 1,
								width: "100%",
								maxWidth: "800px",
								overflow: "auto"
							}}
						>
							<Typography variant="body2" component="pre" sx={{ fontFamily: "monospace" }}>
								{error.toString()}
							</Typography>
						</Box>
					)}
					<Button 
						variant="contained" 
						color="primary" 
						onClick={() => window.location.reload()}
						sx={{ mt: 2 }}
					>
						Reload Application
					</Button>
				</Box>
			);
		}

		return children;
	}

	resetError = () => {
		this.setState({
			hasError: false,
			error: null,
			errorInfo: null
		});
	};
}

export default RootErrorBoundary;