import { Draggable, Droppable } from "react-beautiful-dnd";
import { Divider, Typography } from "@mui/material";

import { SidebarContainer } from "../../styled/formula-sidebar.styled";
import { RuleSidebarContents } from "../../styled/rule-sidebars.styled";
import { AdvancedTriggerItemWrapper } from "../../triggers/AdvancedTriggerItemWrapper";

export default function AdvancedTriggersContainer(props) {

	return (
		<Droppable droppableId="advancedTriggers" isDropDisabled={true}>
			{(provided, snapshot) => (
				<div
					{...provided.droppableProps}
					ref={provided.innerRef}
					style={props.getListStyle(snapshot.isDraggingOver)}
				>
					<SidebarContainer sx={{
						height: "286px",
						marginTop: "0px",
						width: "350px"
					}}>
						<Typography sx={{ padding: "5px" }} variant="body1">Advanced Triggers</Typography>
						<Divider sx={{ width: "100%" }} />
						<RuleSidebarContents sx={{ height: "250px", overflowY: "auto", paddingBottom: "5px", paddingTop: "5px" }}>
							{
								props.advancedTriggers.map((advancedTrigger, index) =>
									<Draggable key={index} draggableId={index.toString() + "-advancedTrigger"} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={props.getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
													advancedTrigger
												)}
											>
												<AdvancedTriggerItemWrapper
													copyAdvancedTriggerToFormula={props.copyAdvancedTriggerToFormula}
													index={index}
													item={advancedTrigger}
												/>
											</div>
										)}
									</Draggable>
								)
							}
						</RuleSidebarContents>
					</SidebarContainer>
				</div>
			)}
		</Droppable>
	);
}