import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../hooks/useAuthHeader";
import { ErrorBoundary, ErrorCard, LoadingCard } from "../../errors";
import { userValidation } from "../../errors";

import UserProfile from "./UserProfile";

// Simplified validation function that won't cause re-renders
const validateUserIdSafe = (id) => {
	try {
		return userValidation.validateUserId(id);
	} catch (error) {
		console.error("Error validating user ID:", error);
		return false;
	}
};

const UserProfileWithErrorBoundary = () => {
	const { id } = useParams();
	const authHeader = useAuthHeader();
	const [isLoading, setIsLoading] = useState(true);
	const [userError, setUserError] = useState(null);
	
	// Check for problematic URL parameters
	useEffect(() => {
		const searchParams = window.location.search;
		if (searchParams) {
			console.warn("URL contains query parameters which might cause issues:", searchParams);
		}
	}, []);
	
	// Manual implementation of resource verification to avoid issues
	useEffect(() => {
		// Skip if missing required data
		if (!id || !authHeader) {
			return;
		}
		
		// Validate ID format first
		if (!validateUserIdSafe(id)) {
			console.log("Invalid user ID format:", id);
			setUserError(new Error("Invalid user ID format"));
			setIsLoading(false);
			return;
		}
		
		// Abort controller for cleanup
		const controller = new AbortController();
		
		// Check user exists
		const checkUserExists = async () => {
			try {
				console.log("Making API request for user:", id);
				const response = await axios.get(
					apiRoutes.getUser(0, id, ""),
					{ 
						headers: authHeader,
						signal: controller.signal
					}
				);
				
				// Verify we got data
				if (!response || !response.data) {
					throw new Error("User not found or invalid data received");
				}
				
				// Success - update state
				setIsLoading(false);
			} catch (error) {
				if (!axios.isCancel(error)) {
					console.log("Error fetching user data:", error);
					setUserError(new Error("User not found or unavailable"));
					setIsLoading(false);
				}
			}
		};
		
		checkUserExists();
		
		return () => {
			controller.abort();
		};
	}, [id, authHeader]);

	if (isLoading) {
		console.log("Rendering loading UI");
		return (
			<LoadingCard
				title="Loading User Profile"
				message="Please wait while we load the user profile..."
				subMessage="If this takes more than a few seconds, the user may not exist."
			/>
		);
	}

	if (userError) {
		console.log("Rendering error UI:", userError.message);
		return (
			<ErrorCard 
				title="User Profile Error"
				message={userError.message}
				returnPath="/settings/users"
				returnButtonText="Return to Users"
			/>
		);
	}

	return (
		<ErrorBoundary parentPath="/settings/users">
			<UserProfile />
		</ErrorBoundary>
	);
};

export default UserProfileWithErrorBoundary;