import { useEffect, useMemo, useRef, useState } from "react";
import { Delete, FilterAlt } from "@mui/icons-material";
import { Grid2, Skeleton, Typography } from "@mui/material";
import {
	getGridDateOperators, GridPagination, GridToolbarFilterButton,
	GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";

import {
	arrayOperators, handleArrayFiltering, handleDateFiltering,
	handleUserFiltering, identifierColumnOperators, statusOperators
} from "../../../../helpers/datagridFiltering";
import {
	CoAppDataGrid, CoAppDataGridContainer,
	CoAppDataGridPaginationContainer, CoAppDataGridToolbar
} from "../../styled/global.styled";
import CoAppIconTextButton from "../inputs/CoAppIconTextButton";

import CoAppDataGridAllSelectedRow from "./CoAppDataGridAllSelectedRow";
import DataGridCustomNoRowsOverlay from "./DataGridCustomNoRowsOverlay";

const arrayColumns = ["roles", "permissions", "groups", "rules", "users", "plugins"];
const dateColumns = ["lastPhoneHome", "lastLogUpload", "updatedAt", "lastHeartbeat", "createdAt"];

const datagridInitialState = {
	filter: {
		filterModel: {
			items: [],
			quickFilterExcludeHiddenColumns: true,
		},
	},
	pagination: {
		paginationModel: { pageSize: 50, page: 0 },
	}
};

export default function CoAppStandardDataGrid(props) {
	const {
		columns, rows, pinnedColumns,
		allowSelection = true, targetResource, parentResource = "",
		parentResourceName = "", bulkActionText = "Delete", selectionModel = [],
		setSelectionModel, handleModalToggle, customSelectionRow = null,
		customSelectionChangeHandler = null, sortModel = [], handleSortModelChange,
		loading
	} = props;

	const [containerHeight, setContainerHeight] = useState("");
	const containerRef = useRef(null);

	const invisibleFields = ["id", "uuid"];
	const filterableColumns = useMemo(
		() => columns
			.filter((column) => !invisibleFields.includes(column.field))
			.map((column) => {
				const baseColumn = { ...column, resizable: true };
				if (arrayColumns.includes(column.field)) {
					return {
						...baseColumn,
						getApplyQuickFilterFn: handleArrayFiltering,
						filterOperators: arrayOperators
					};
				} else if (dateColumns.includes(column.field)) {
					return {
						...baseColumn,
						getApplyQuickFilterFn: handleDateFiltering,
						filterOperators: getGridDateOperators(true)
					};
				} else if (column.field === "user") {
					return {
						...baseColumn,
						getApplyQuickFilterFn: handleUserFiltering,
						filterOperators: identifierColumnOperators
					};
				} else if (column.field === "status" || column.field === "active" || column.field === "killSwitchActive") {
					return {
						...baseColumn,
						filterOperators: statusOperators
					};
				} else {
					return baseColumn;
				}
			}),
		[columns],
	);

	const handleSelectionModelChange = (newSelectionModel) => {
		if (rows[0]?.isDummyRow) return;
		setSelectionModel(newSelectionModel);
	};

	function CustomToolbar() {
		return (
			<div style={{ width: "100%" }}>
				<CoAppDataGridToolbar container>
					<Grid2 item xxl={0.1} xl={0.1} lg={0.2} sm={0.2} />
					<Grid2 item xxl={1} xl={1} lg={1.2} sm={1.5}>
						{
							loading ?
								<Skeleton variant="rectangular" width={100} height={35} sx={{ borderRadius: "10px" }} />
								:
								<GridToolbarFilterButton />
						}
					</Grid2>
					<Grid2
						item
						xxl={parentResource && parentResourceName ? 3.25 : 4}
						xl={parentResource && parentResourceName ? 3.25 : 4}
						lg={parentResource && parentResourceName ? 3 : 3.5}
						sm={parentResource && parentResourceName ? 2.5 : 3}
					>
						{
							loading
								?
								<Skeleton variant="rectangular" width={200} height={35} sx={{ borderRadius: "10px" }} />
								:
								<GridToolbarQuickFilter sx={{ width: { xxl: "220px", xl: "220px", lg: "200px", sm: "150px" } }} />
						}
					</Grid2>
					{
						selectionModel.length > 0 && targetResource !== "workstations" ?
							<Grid2 item>
								<CoAppIconTextButton
									icon={<Delete sx={{ fontSize: "1rem", marginRight: "5px", alignSelf: "baseline", marginTop: "2px" }} />}
									text={
										<Typography color="text.error" variant="body2" align="left" sx={{ width: "450px" }}>
											{`${bulkActionText} selected ${selectionModel.length > 1 ? targetResource : targetResource.substring(0, targetResource.length - 1)} ${selectionModel.length > 1 ? `(${selectionModel.length})` : ""} ${parentResource && parentResourceName ? ` from ${parentResourceName} ${parentResource}` : targetResource === "workstations" ? "kill switch" : ""}`}
										</Typography>
									}
									isDestructive={true}
									onClick={() => handleModalToggle()}
								/>
							</Grid2>
							:
							null

					}
					< CoAppDataGridPaginationContainer item >
						{
							loading
								?
								<Skeleton variant="rectangular" width={200} height={35} sx={{ borderRadius: "10px", marginRight: "10px" }} />
								:
								<GridPagination rowsPerPageOptions={[]} />
						}
					</CoAppDataGridPaginationContainer>
					{
						customSelectionRow
							?
							<>
								{customSelectionRow}
							</>
							:
							<CoAppDataGridAllSelectedRow
								count={selectionModel.length}
								selectedResource={targetResource}
								parentResource={parentResource}
								parentResourceName={parentResourceName}
								onClickHandler={() => handleSelectionModelChange([])}
								shouldShow={selectionModel.length > 0 && selectionModel.length === rows.length && targetResource !== "workstations"}
							/>

					}
				</CoAppDataGridToolbar >
			</div >
		);
	}

	const handleContainerHeightChange = () => {
		if (containerRef.current) {
			const yPosition = containerRef.current.getBoundingClientRect().y;
			const windowHeight = window.innerHeight;
			const appliedPadding = 20;
			setContainerHeight(`${windowHeight - yPosition - appliedPadding}px`);
		} else {
			setContainerHeight("");
		}
	};

	useEffect(() => {
		handleContainerHeightChange();
		window.addEventListener("resize", handleContainerHeightChange);

		return () => window.removeEventListener("resize", handleContainerHeightChange);
	}, [containerRef.current, containerHeight]);

	return (
		<CoAppDataGridContainer ref={containerRef} height={containerHeight}>
			<CoAppDataGrid
				isworkstation={targetResource === "workstations" ? "true" : "false"}
				columnHeaderHeight={56}
				columns={filterableColumns}
				rows={rows}
				isRowSelectable={(params) => params.row.isCoAppSuperAdmin !== undefined ? !params.row.isCoAppSuperAdmin : true}
				disableColumnResize={false}
				disableRowSelectionOnClick
				getRowId={(row) => row["id"] ? row["id"] : row["uuid"]}
				checkboxSelection={allowSelection}
				initialState={{
					...datagridInitialState,
					pinnedColumns: { left: pinnedColumns }
				}}
				pagination
				slots={{
					toolbar: CustomToolbar,
					noResultsOverlay: DataGridCustomNoRowsOverlay,
					noRowsOverlay: DataGridCustomNoRowsOverlay,
					openFilterButtonIcon: FilterAlt
				}}
				slotProps={{
					noResultsOverlay: { message: `No ${targetResource} found.` },
					noRowsOverlay: { message: `No ${targetResource} found.` },
					filterPanel: {
						filterFormProps: {
							// Customize inputs by passing props
							logicOperatorInputProps: {
								size: "small",
							},
							columnInputProps: {
								size: "small",
							},
							operatorInputProps: {
								size: "small",
							},
							valueInputProps: {
								InputComponentProps: {
									size: "small",
								},
							},
						},
						sx: {
							// Customize inputs using css selectors
							"& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
							"& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormValueInput": { width: 200 },
						},
					},
				}}
				disableColumnMenu
				hideFooter
				onRowSelectionModelChange={customSelectionChangeHandler ? customSelectionChangeHandler : handleSelectionModelChange}
				rowSelectionModel={selectionModel}
				getRowClassName={(params) => params.row?.uuid !== undefined && params.row?.killSwitchActive ? "CustomKillSwitchRow--active" : ""}
				sortModel={sortModel}
				onSortModelChange={handleSortModelChange}
			/>
		</CoAppDataGridContainer>
	);
}