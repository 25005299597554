import { Draggable, Droppable } from "react-beautiful-dnd";
import { Add } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";

import { SidebarContainer, SidebarRow, SidebarRowAddButton } from "../../styled/formula-sidebar.styled";
import { RuleSidebarContents } from "../../styled/rule-sidebars.styled";

export default function TriggersContainer(props) {

	return (
		<Droppable droppableId="triggers" isDropDisabled={true}>
			{(provided, snapshot) => (
				<div
					{...provided.droppableProps}
					ref={provided.innerRef}
					style={props.getListStyle(snapshot.isDraggingOver)}
				>
					<SidebarContainer sx={{
						height: "286px",
						marginTop: "0px",
					}}>
						<Typography sx={{ padding: "5px" }} variant="body1">
													Triggers
						</Typography>
						<Divider sx={{ width: "100%" }} />
						<RuleSidebarContents sx={{ height: "250px", overflowY: "auto" }}>
							{
								props.triggers.map((trigger, index) =>
									<Draggable key={index} draggableId={index.toString() + "-trigger"} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={props.getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
													trigger
												)}
											>
												<SidebarRow>
													<Typography sx={{ fontWeight: "600" }} variant="body2">
														{trigger.triggerName}
													</Typography>
													<>
														{trigger.location.name ? (
															<div>
																<SidebarRowAddButton onClick={() => props.addTriggerToFormula(trigger.id)}>
																	<Add sx={{ cursor: "pointer" }} />
																</SidebarRowAddButton>
																<Box sx={{ display: "flex", flexDirection: "column" }}>
																	<Typography variant="body2">Location: {trigger.location.name}</Typography>
																	<Typography variant="body2">Field Name: {trigger.triggerField.name}</Typography>
																	<Typography variant="body2">Field Type: {trigger.triggerField.fieldType}</Typography>
																</Box>
															</div>
														) : null}
													</>
												</SidebarRow>
												<Divider sx={{ width: "100%", paddingTop: "5px" }} />
											</div>
										)}
									</Draggable>
								)
							}
						</RuleSidebarContents>
					</SidebarContainer>
				</div>
			)}
		</Droppable>
	);
	
}