import { createRoutesFromElements, Navigate, Route } from "react-router-dom";

import RouteErrorBoundary from "./components/errors/RouteErrorBoundary";
import Extension from "./components/extension/Extension";
import NoPageFound from "./components/global/components/NoPageFound";
import Login from "./components/login/SignIn";
import ManagerApprovalRequest from "./components/requests/ManagerApprovalRequest";
import RuleLibraryDataGrid from "./components/rule-library/RuleLibraryDataGrid";
import RuleTrashDataGrid from "./components/rule-library/RuleTrashDataGrid";
import RuleWizard from "./components/rule-wizard/RuleBuilder";
import RuleBuilderWithErrorBoundary from "./components/rule-wizard/RuleBuilderWithErrorBoundary";
import GroupPlugins from "./components/settings/groups/group/GroupPlugins";
import GroupRules from "./components/settings/groups/group/GroupRules";
import GroupSSO from "./components/settings/groups/group/GroupSSO";
import GroupUsers from "./components/settings/groups/group/GroupUsers";
import GroupManagement from "./components/settings/groups/GroupManagement";
import GroupWithErrorBoundary from "./components/settings/groups/GroupWithErrorBoundary";
import OrganizationManagement from "./components/settings/organization/OrganizationManagement";
import ScimWizard from "./components/settings/organization/scim-wizard/ScimWizard";
import PluginGroups from "./components/settings/plugins/plugin/PluginGroups";
import PluginGroupsWithErrorBoundary from "./components/settings/plugins/plugin/PluginGroupsWithErrorBoundary";
import PluginHomePageWithErrorBoundary from "./components/settings/plugins/plugin/PluginHomePageWithErrorBoundary";
import PluginManagement from "./components/settings/plugins/PluginManagement";
import RolePermissions from "./components/settings/roles/role/RolePermissions";
import RoleSSO from "./components/settings/roles/role/RoleSSO";
import RoleUsers from "./components/settings/roles/role/RoleUsers";
import RoleManagement from "./components/settings/roles/RoleManagement";
import RoleWithErrorBoundary from "./components/settings/roles/RoleWithErrorBoundary";
import UserManagement from "./components/settings/users/UserManagement";
import UserProfileWithErrorBoundary from "./components/settings/users/UserProfileWithErrorBoundary";
import WorkstationManagement from "./components/settings/workstations/WorkstationManagement";
import Swagger from "./components/swagger/Swagger";
import ProtectedRoute from "./ProtectedRoute";
import WithNavRoute from "./WithNavRoute";
import WithoutNavRoute from "./WithoutNavRoute";

const appRoutes = createRoutesFromElements(
	<Route>
		<Route element={<WithoutNavRoute />}>
			<Route path="/" element={<Login />} />
			<Route path="/extension" element={<Extension />} />
			<Route exact path="/requests/:action/:requestId" element={<ManagerApprovalRequest />} />
			<Route exact path="/swagger" element={<Swagger />} />
		</Route>
		<Route element={<WithNavRoute />}>
			{/* Redirect /rules and /rules/ to /rules/library */}
			<Route exact path="/rules" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/rules" element={<Navigate to="/rules/library" replace />} />
			</Route>
			<Route exact path="/rules/" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/rules/" element={<Navigate to="/rules/library" replace />} />
			</Route>
			
			<Route path="/rules/new" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/rules/new" element={<RuleWizard />} />
			</Route>
			<Route path="/rules/:id" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/rules/:id" element={<RuleBuilderWithErrorBoundary />} />
			</Route>
			<Route path="/rules/library" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
				<Route path="/rules/library" element={
					<RouteErrorBoundary parentPath="/rules/library" resourceName="rule library">
						<RuleLibraryDataGrid />
					</RouteErrorBoundary>
				} />
			</Route>
			<Route exact path="/rules/trash" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_RULES_TAGS", "VIEW_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/rules/trash" element={<RuleTrashDataGrid />} />
			</Route>
			<Route exact path='/settings/users' element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path='/settings/users' element={<UserManagement />} />
			</Route>
			<Route exact path='/settings/users/:id' element={<ProtectedRoute permissions={["ACCESS_MP"]} isInclusive={true} />}>
				<Route exact path='/settings/users/:id' element={<UserProfileWithErrorBoundary />} />
			</Route>
			<Route path="/settings/groups" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/groups" element={<GroupManagement />} />
			</Route>
			<Route path="/settings/groups/:groupId/rules" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/groups/:groupId/rules" element={
					<GroupWithErrorBoundary>
						<GroupRules />
					</GroupWithErrorBoundary>
				} />
			</Route>
			<Route path="/settings/groups/:groupId/plugins" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/groups/:groupId/plugins" element={
					<GroupWithErrorBoundary>
						<GroupPlugins />
					</GroupWithErrorBoundary>
				} />
			</Route>
			<Route path="/settings/groups/:groupId/users" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/groups/:groupId/users" element={
					<GroupWithErrorBoundary>
						<GroupUsers />
					</GroupWithErrorBoundary>
				} />
			</Route>
			<Route path="/settings/groups/:groupId/sso" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/groups/:groupId/sso" element={
					<GroupWithErrorBoundary>
						<GroupSSO />
					</GroupWithErrorBoundary>
				} />
			</Route>
			<Route exact path="/settings/organization" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_ORG_KILLSWITCH", "MANAGE_ORG_FORCELOGIN", "MANAGE_SSOSCIM"]} isInclusive={false} />}>
				<Route exact path="/settings/organization" element={<OrganizationManagement />} />
			</Route>
			<Route path="/settings/roles/:roleId/permissions" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/roles/:roleId/permissions" element={
					<RoleWithErrorBoundary>
						<RolePermissions />
					</RoleWithErrorBoundary>
				} />
			</Route>
			<Route path="/settings/roles/:roleId/sso" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/roles/:roleId/sso" element={
					<RoleWithErrorBoundary>
						<RoleSSO />
					</RoleWithErrorBoundary>
				} />
			</Route>
			<Route path="/settings/roles/:roleId/users" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/roles/:roleId/users" element={
					<RoleWithErrorBoundary>
						<RoleUsers />
					</RoleWithErrorBoundary>
				} />
			</Route>
			<Route path="/settings/roles" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_USERS_AND_GROUPS"]} isInclusive={true} />}>
				<Route exact path="/settings/roles" element={<RoleManagement />} />
			</Route>
			<Route path="/settings/organization/sso-scim" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_SSOSCIM"]} isInclusive={true} />}>
				<Route exact path="/settings/organization/sso-scim" element={<ScimWizard />} />
			</Route>
			<Route exact path="/settings/workstations" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_WORKSTATIONS"]} isInclusive={true} />}>
				<Route exact path="/settings/workstations" element={<WorkstationManagement />} />
			</Route>
			<Route path="/settings/plugins/:pluginId/groups" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_PLUGINS"]} isInclusive={true} />}>
				<Route exact path="/settings/plugins/:pluginId/groups" element={<PluginGroupsWithErrorBoundary />} />
			</Route>
			<Route path="/settings/plugins" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_PLUGINS"]} isInclusive={true} />}>
				<Route exact path="/settings/plugins" element={<PluginManagement />} />
			</Route>
			<Route path="/settings/plugins/:pluginId" element={<ProtectedRoute permissions={["ACCESS_MP", "MANAGE_PLUGINS"]} isInclusive={true} />}>
				<Route exact path="/settings/plugins/:pluginId" element={<PluginHomePageWithErrorBoundary />} />
			</Route>
		</Route>
		<Route path="*" element={<NoPageFound />} />
	</Route>
);

export default appRoutes;