import React from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useResourceVerifier from "../../../hooks/useResourceVerifier";
import { ErrorBoundary, ErrorCard, LoadingCard,roleValidation } from "../../errors";

const RoleWithErrorBoundary = ({ children }) => {
	const { roleId } = useParams();
	const location = useLocation();
	const authHeader = useAuthHeader();
	
	const { 
		isLoading, 
		resourceError: roleError, 
		resourceData: roleData 
	} = useResourceVerifier({
		id: roleId,
		validateIdFn: roleValidation.validateRoleId,
		fetchResourceFn: () => axios.get(
			apiRoutes.getRoleById(roleId),
			{ headers: authHeader }
		),
		validateResponseFn: (response) => response && response.data && !response.data.error,
		authHeader,
		invalidIdMsg: "Invalid role ID format",
		timeoutMsg: "Timeout. Role may not exist or has been removed."
	});

	const canRenderChildren = !isLoading && !roleError && roleData && 
		location.state && typeof location.state === "object";

	// If we don't have state but need it, construct a basic one for the child components
	const getStateForChildren = () => {
		if (!location.state && roleData) {
			return {
				name: roleData.name || `Role ${roleId}`,
				id: roleId
			};
		}
		return location.state;
	};

	if (isLoading) {
		return (
			<LoadingCard
				title="Loading Role"
				message="Please wait while we load the role..."
				subMessage="If this takes more than a few seconds, the role may not exist."
			/>
		);
	}

	if (roleError) {
		return (
			<ErrorCard 
				title="Role Not Found"
				message={roleError.message}
				returnPath="/settings/roles"
				returnButtonText="Return to Roles"
			/>
		);
	}

	return (
		<ErrorBoundary parentPath="/settings/roles">
			{React.cloneElement(children, { 
				...children.props,
				...(canRenderChildren ? {} : { state: getStateForChildren() })
			})}
		</ErrorBoundary>
	);
};

export default RoleWithErrorBoundary;