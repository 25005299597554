import {
	Dialog, DialogActions, DialogContent,
	DialogTitle, Divider, FormControl,
	FormHelperText, MenuItem
} from "@mui/material";

import CoAppTextField from "../../global/components/inputs/CoAppTextField";
import { CoAppActionButton, CoAppCloseIcon, CoAppInputLabel, CoAppSelect, CoAppTextButton } from "../../global/styled/global.styled";

export default function ReactionDialog(props) {
	let reactionTypes = [...props.reactionTypes];
	const hasPopupReaction = props.currentReactions.filter(reaction => reaction.reactionType.includes("popup")).length > 0;
	if (hasPopupReaction && !props.isEdit) {
		reactionTypes = reactionTypes.filter(reaction => !reaction.name.includes("popup"));
	} else if (hasPopupReaction && props.isEdit && !props.typeValue.includes("popup")) {
		reactionTypes = reactionTypes.filter(reaction => !reaction.name.includes("popup"));
	}

	const handleInvalidNumericValues = (e) => {
		if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
			e.preventDefault();
		}
	};

	return (
		<Dialog open={props.isOpen || props.isEdit} onClose={props.dialogCancelHandler}>
			<DialogActions sx={{ justifyContent: "space-between" }} >
				<DialogTitle>{props.isEdit ? "Edit Reaction" : "Add New Reaction"}</DialogTitle>
				<CoAppCloseIcon top={10} left={282} onClick={props.isEdit ? props.editCancelHandler : props.dialogCancelHandler}></CoAppCloseIcon>
			</DialogActions>

			<Divider sx={{ width: "100%" }} />

			<DialogContent sx={{ width: "325px" }}>

				<FormControl sx={{ display: "block", marginTop: "12px", marginBottom: "12px" }} error={props.typeValueError !== ""}>
					<CoAppInputLabel>Type of Reaction</CoAppInputLabel>
					<CoAppSelect
						sx={{ width: "100%" }}
						labelId="reactionType"
						id="reactionType"
						label="Type of Reaction"
						value={props.typeValue}
						onChange={props.typeDropdownValueChangeHandler}
					>
						{reactionTypes
							.slice()
							.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
							.map(item =>
								<MenuItem key={item.name} value={item.name}>
									{item.friendlyName}
								</MenuItem>
							)}
					</CoAppSelect>

					<FormHelperText>{props.typeValueError}</FormHelperText>
				</FormControl>

				{
					props.typeValue !== "" && !["popup", "userconfirmationpopup", "managerapprovalpopup", "freeresponsepopup"].includes(props.typeValue) ?

						<FormControl sx={{ display: "block" }} error={props.fieldValueError !== ""}>
							<CoAppInputLabel id="reactionField">Reaction Field</CoAppInputLabel>
							<CoAppSelect
								sx={{ width: "100%" }}
								labelId="reactionField"
								id="reactionField"
								value={props.fieldValue}
								label="Reaction Field"
								onChange={props.fieldDropdownValueChangeHandler}
								error={props.fieldValueError !== ""}
							>
								{props.reactionFields
									.slice()
									.sort((a, b) => a.name.localeCompare(b.name))
									.map(item =>
										<MenuItem key={item.id} value={JSON.stringify(item)}>
											{item.name}
										</MenuItem>
									)}
							</CoAppSelect>
							<FormHelperText>{props.fieldValueError}</FormHelperText>
						</FormControl>
						:
						null
				}
				{props.typeValue !== "" ?
					<CoAppTextField
						margin="dense"
						id="reactionText"
						label="Text Displayed in Reaction"
						type="text"
						fullWidth
						value={props.reactionText}
						onChange={props.reactionTextInputHandler}
						error={props.reactionTextValueError !== ""}
						helperText={props.reactionTextValueError}
						inputProps={{ maxLength: 255 }}
					/>
					:
					null
				}
				{
					props.typeValue !== "" && ["freeresponsepopupe", "userconfirmationpopup"].includes(props.typeValue) ?
						<CoAppTextField
							margin="dense"
							id="reactionConfirmationText"
							label="User Confirmation Text"
							type="text"
							fullWidth
							value={props.userConfirmationText}
							onChange={props.userConfirmationTextInputHandler}
							error={props.userConfirmationTextValueError !== ""}
							helperText={props.userConfirmationTextValueError}
							inputProps={{ maxLength: 20 }}
						/>
						:
						null
				}
				{
					props.typeValue !== "" && props.typeValue === "freeresponsepopup" ?
						<CoAppTextField
							margin="dense"
							id="minimumCharacterCount"
							label="Minimum Character Count"
							type="number"
							fullWidth
							value={props.minimumCharacterCount}
							onChange={props.handleMinimumCharacterCountChange}
							onKeyDown={handleInvalidNumericValues} //disallows typing of non-numeric characters (E, e, -, +) that the input of type number allows by default
							onWheel={(e) => e.target.blur()} //disallows scroll via mouse wheel to change value
							inputProps={{ min: 1 }}
						/>
						:
						null
				}
			</DialogContent>
			<Divider sx={{ width: "100%" }} />
			<DialogActions>
				<CoAppTextButton onClick={props.dialogCancelHandler}>Cancel</CoAppTextButton>
				<CoAppActionButton disabled={props.formHasErrors} onClick={props.isEdit ? props.editSaveReactionHandler : props.saveReactionHandler}>Save</CoAppActionButton>
			</DialogActions>
		</Dialog >
	);
}