import axios from "axios";

import apiRoutes from "../../constants/api-routes";

export const validateRuleId = (id) => {
	if (isNaN(id)) {
		return false;
	}
	
	return Number(id) > 0 && Number.isInteger(Number(id));
};

export const createRuleExistenceChecker = (authHeader) => {
	return async (id) => {
		try {
			const response = await axios.get(apiRoutes.getRule(id), { headers: authHeader });
			return !!response.data && !response.data.error;
		} catch (error) {
			console.error("Rule not found:", error);
			return false;
		}
	};
};

export default {
	validateRuleId,
	createRuleExistenceChecker
};