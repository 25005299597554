import { Draggable, Droppable } from "react-beautiful-dnd";
import { Typography } from "@mui/material";

import { WizardOperatorContainer, WizardOperatorGrid, WizardOperatorItem } from "../../styled/rule-wizard.styled";

export default function OperatorsContainer(props) {

	return (
		<WizardOperatorContainer>
			<Typography variant="body1">Operators</Typography>
			{
				<Droppable droppableId="operators" direction="horizontal" isDropDisabled={true}>
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={props.getListStyle(snapshot.isDraggingOver)}
						>
							<WizardOperatorGrid container >

								{props.operators.map((op, index) =>
									<Draggable key={index} draggableId={index.toString() + "-operator"} index={index} >
										{(provided, snapshot) =>
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={props.getFormulaItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												<WizardOperatorItem
													item
													key={op.index}
													value={op.triggerName}
													onClick={() => props.addOperatorToFormula(index, -1)}
												>
													{op.triggerName}
												</WizardOperatorItem>
											</div>
										}
									</Draggable>

								)}
							</WizardOperatorGrid>
						</div>
					)}
				</Droppable>
			}
		</WizardOperatorContainer>
	);
	
}