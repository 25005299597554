import { useParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useResourceVerifier from "../../../../hooks/useResourceVerifier";
import { ErrorBoundary, ErrorCard, LoadingCard } from "../../../errors";
import { validatePluginId } from "../../../errors/pluginValidation";

import PluginGroups from "./PluginGroups";

const PluginGroupsWithErrorBoundary = () => {
	const { pluginId } = useParams();
	const authHeader = useAuthHeader();
	
	const { 
		isLoading, 
		resourceError: pluginError 
	} = useResourceVerifier({
		id: pluginId,
		validateIdFn: validatePluginId,
		fetchResourceFn: () => axios.get(
			apiRoutes.getPlugin(pluginId),
			{ headers: authHeader }
		),
		validateResponseFn: (response) => response && response.data && !response.data.error && response.data.pluginId,
		authHeader,
		invalidIdMsg: "Invalid plugin ID format",
		timeoutMsg: "Timeout. Plugin may not exist or has been removed."
	});

	if (isLoading) {
		console.log("Rendering plugin groups loading UI");
		return (
			<LoadingCard
				title="Loading Plugin Groups"
				message="Please wait while we load the plugin groups..."
				subMessage="If this takes more than a few seconds, the plugin may not exist."
			/>
		);
	}

	if (pluginError) {
		console.log("Rendering plugin groups error UI:", pluginError.message);
		return (
			<ErrorCard 
				title="Plugin Groups Error"
				message={pluginError.message}
				returnPath="/settings/plugins"
				returnButtonText="Return to Plugins"
			/>
		);
	}

	return (
		<ErrorBoundary parentPath="/settings/plugins">
			<PluginGroups />
		</ErrorBoundary>
	);
};

export default PluginGroupsWithErrorBoundary;