import {
	useEffect, useMemo, useRef,
	useState
} from "react";
import { Delete, FilterAlt, Restore } from "@mui/icons-material";
import { Grid2, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { GridPagination, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import messageLevels from "../../constants/message-levels";
import messages from "../../constants/messages";
import { getDataGridFakeData } from "../../helpers/coappDataGridUtils";
import useAuthHeader from "../../hooks/useAuthHeader";
import useDatagridSorting from "../../hooks/useDatagridSorting";
import useToastAlert from "../../hooks/useToastAlert";
import CoAppDataGridDateTimeCell from "../global/components/datagrid/CoAppDataGridDateTimeCell";
import CoAppDataGridHeader from "../global/components/datagrid/CoAppDatagridHeader";
import CoAppDataGridSkeletonCell from "../global/components/datagrid/CoAppDataGridSkeletonCell";
import DataGridCustomNoRowsOverlay from "../global/components/datagrid/DataGridCustomNoRowsOverlay";
import CoAppDestructiveConfirmationModal from "../global/components/modals/CoAppDestructiveConfirmationModal";
import {
	CoAppDataGrid, CoAppDataGridContainer, CoAppDataGridPaginationContainer, CoAppDataGridTextCellContainer,
	CoAppDataGridToolbar,
	CoAppLightTooltip
} from "../global/styled/global.styled";

import RuleDataGridLongTextCell from "./RuleDataGridLongTextCell";
import RuleDataGridSelectionRow from "./RuleDataGridSelectionRow";
import RuleUndoSnackbar from "./RuleUndoSnackbar";

const datagridInitialState = {
	filter: {
		filterModel: {
			items: [],
			quickFilterExcludeHiddenColumns: true,
		},
	},
	pagination: {
		paginationModel: { pageSize: 50, page: 0 },
	}
};

const getDifferenceInDays = (dateToCompare) => {
	let now = new Date();
	const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
	const diffInMs = Math.abs(thirtyDaysAgo - dateToCompare);
	return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
};

const customDeletionInComparator = (v1, v2) => {
	if (!v1 && !v2) return;
	return v1 - v2;
};

const ruleTrashColumns = ["delete", "restore", "name", "description", "permanentDeletionIn", "dateTimeTrashed"];

export default function RuleTrashDataGrid() {
	const theme = useTheme();
	const dummyRows = getDataGridFakeData(ruleTrashColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("ruleTrash");

	const containerRef = useRef(null);
	const [containerHeight, setContainerHeight] = useState("");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarLevel, setSnackbarLevel] = useState("");
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [isBulkUndo, setIsBulkUndo] = useState(false);
	const [rulesToUndo, setRulesToUndo] = useState([]);
	const [ruleBeingRestored, setRuleBeingRestored] = useState({});
	const [selectionModel, setSelectionModel] = useState([]);
	const [rules, setRules] = useState(dummyRows);
	const [loading, setLoading] = useState(true);
	const [destructiveModalOpen, setDestructiveModalOpen] = useState(false);
	const [bulkDestructiveModalOpen, setBulkDestructiveModalOpen] = useState(false);
	const [ruleBeingDeleted, setRuleBeingDeleted] = useState({ name: "" });

	const handleRowSelectionChange = (newSelectionModel) => {
		if (rules[0]?.isDummyRow) return;
		setSelectionModel(newSelectionModel);
	};

	const handleDeleteRule = () => {
		axios.delete(apiRoutes.deleteRule + "/" + ruleBeingDeleted.id + "?isHardDelete=1", {
			headers: authHeader
		})
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.PERM_DELETE_RULE_MSG(false));
				handleDeleteRuleToggle();
				initTrashLibrary();
			})
			.catch(err => {
				console.error(err);
				handleToastAlert(messageLevels.ERROR, messages.PERM_DELETE_RULE_ERROR_MSG);
			});
	};

	const handleRestoreRule = (ruleId) => {
		axios.put(apiRoutes.restoreRule(ruleId), {}, {
			headers: authHeader
		})
			.then(() => {
				setSnackbarMessage(messages.RESTORE_RULE_MSG);
				setSnackbarLevel("info");
				setSnackbarOpen(true);
				setIsBulkUndo(false);
				setRuleBeingRestored(rules.find(rule => rule.id === ruleId));
				initTrashLibrary();
			})
			.catch(err => {
				console.error(err);
				handleToastAlert(messageLevels.ERROR, messages.RULE_RESTORE_ERROR_MSG);
			});
	};

	const handleBulkRuleRestoration = () => {
		const restorationData = {
			ruleIds: selectionModel
		};

		axios.put(apiRoutes.bulkRestoreRules, restorationData, { headers: authHeader })
			.then(() => {
				setSnackbarMessage(messages.SEND_RULE_TO_TRASH_TOAST_MSG(selectionModel.length > 1));
				setSnackbarLevel("info");
				setSnackbarOpen(true);
				setIsBulkUndo(true);
				setRulesToUndo(selectionModel);
				initTrashLibrary();
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.BULK_RESTORE_FAILED_TOAST_MSG);
			});
	};

	const handleBulkRuleDeletion = () => {
		const deletionData = {
			ruleIds: selectionModel
		};

		axios.delete(apiRoutes.bulkPermanentDeleteRules + "?isHardDelete=1", { headers: authHeader, data: deletionData })
			.then(() => {
				handleToastAlert(messageLevels.INFO, messages.PERM_DELETE_RULE_MSG(true));
				initTrashLibrary();
				setBulkDestructiveModalOpen(false);
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.PERM_DELETE_RULE_ERROR_MSG);
			});
	};

	const handleUndoBulkRestoration = () => {
		const deleteData = {
			ruleIds: rulesToUndo
		};
		axios.delete(apiRoutes.bulkDeleteRules + "?isHardDelete=0", { headers: authHeader, data: deleteData })
			.then(() => {
				initTrashLibrary();
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.UNDO_FAILED_MSG("bulk restoration of rules"));
			});
	};

	const handleUndoRestoration = () => {
		axios.delete(apiRoutes.deleteRule + "/" + ruleBeingRestored.id, { headers: authHeader })
			.then(() => {
				setRulesToUndo(selectionModel);
				initTrashLibrary();
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.UNDO_FAILED_MSG("restoration of rule"));
			});
	};

	const columns = [
		{
			field: "name",
			headerName: "Rule name",
			flex: 1,
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<RuleDataGridLongTextCell value={params.value} isTrashed={true} />}
				/>
			)
		},
		{
			field: "description",
			headerName: "Description",
			flex: 1,
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<RuleDataGridLongTextCell value={params.value} isTrashed={true} />}
				/>
			)
		},
		{
			field: "permanentDeletionIn",
			headerName: "Permanent deletion in",
			flex: 1,
			sortable: true,
			sortComparator: customDeletionInComparator,
			renderCell: (params) => {
				const diff = getDifferenceInDays(new Date(params.row.dateTimeTrashed));
				return (
					<CoAppDataGridSkeletonCell
						cellValue={params.value}
						cellToRender={
							<CoAppDataGridTextCellContainer>
								<Typography variant="body2" color="text.disabled">{`${diff === 1 ? `${diff} day` : `${diff} days`}`}</Typography>
							</CoAppDataGridTextCellContainer>
						}
					/>
				);
			},
			filterable: false
		},
		{
			field: "dateTimeTrashed",
			headerName: "Moved to trash",
			flex: 1,
			type: "dateTime",
			valueGetter: (params) => !params ? null : new Date(params),
			sortable: true,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} isTrashed={true} />}
				/>
			)
		},
		{
			field: "delete",
			type: "actions",
			sortable: false,
			flex: .15,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppLightTooltip title="Permanently delete">
							<IconButton onClick={() => handleDeleteRuleToggle(params.row)}>
								<Delete sx={{ color: theme.palette.icon.red }} />
							</IconButton>
						</CoAppLightTooltip>
					}
				/>
			)
		},
		{
			field: "restore",
			type: "actions",
			sortable: false,
			flex: .15,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={(
						<CoAppLightTooltip title="Restore">
							<IconButton onClick={() => handleRestoreRule(params.row.id)}>
								<Restore sx={{ color: theme.palette.icon.grey }} />
							</IconButton>
						</CoAppLightTooltip>
					)}
				/>
			)
		}
	];

	const invisibleFields = ["id"];
	const filterableColumns = useMemo(
		() => columns
			.filter((column) => !invisibleFields.includes(column.field)),
		[columns]);

	function CustomToolbar() {
		return (
			<div style={{ width: "100%" }}>
				<CoAppDataGridToolbar container>
					<Grid2 item xxl={0.1} xl={0.1} lg={0.2} sm={0.2} />
					<Grid2 item xxl={1} xl={1} lg={1.2} sm={1.5}>
						{loading ?
							<Skeleton variant="rectangular" width={100} height={35} sx={{ borderRadius: "10px" }} />
							:
							<GridToolbarFilterButton />
						}
					</Grid2>
					<Grid2 item xxl={4} xl={4} lg={3.5} sm={3}>
						{
							loading ?
								<Skeleton variant="rectangular" width={200} height={35} sx={{ borderRadius: "10px" }} />
								:
								<GridToolbarQuickFilter sx={{ width: { xxl: "220px", xl: "220px", lg: "200px", sm: "150px" } }} />
						}
					</Grid2>
					<CoAppDataGridPaginationContainer item>
						{
							loading ?
								<Skeleton variant="rectangular" width={200} height={35} sx={{ borderRadius: "10px", marginRight: "10px" }} />
								:
								<GridPagination rowsPerPageOptions={[]} />
						}
					</CoAppDataGridPaginationContainer>
					<RuleDataGridSelectionRow
						selectedRules={selectionModel}
						totalRules={rules}
						isTrash={true}
						shouldShow={selectionModel.length > 0}
						onClearSelectionClickHandler={() => setSelectionModel([])}
						onRestoreClickHandler={handleBulkRuleRestoration}
						onDeleteClickHandler={() => setBulkDestructiveModalOpen(true)}
					/>
				</CoAppDataGridToolbar>
			</div>
		);
	}

	const initTrashLibrary = () => {
		axios.get(apiRoutes.getRules + "?isTrashed=1", { headers: authHeader })
			.then(res => {
				res.data.rules.forEach(rule => {
					rule["permanentDeletionIn"] = getDifferenceInDays(new Date(rule.dateTimeTrashed));
				});
				setRules(res.data.rules);
				setLoading(false);
			})
			.catch(err => console.log(err));
	};

	const handleDeleteRuleToggle = (rule = { name: "" }) => {
		setDestructiveModalOpen(prev => !prev);
		setRuleBeingDeleted(rule);
	};

	const handleContainerHeightChange = () => {
		if (containerRef.current) {
			const yPosition = containerRef.current.getBoundingClientRect().y;
			const windowHeight = window.innerHeight;
			const appliedPadding = 20;
			setContainerHeight(`${windowHeight - yPosition - appliedPadding}px`);
		} else {
			setContainerHeight("");
		}
	};

	useEffect(() => {
		initTrashLibrary();
		handleInitializeSortModel();
		handleContainerHeightChange();
		window.addEventListener("resize", handleContainerHeightChange);

		return () => window.removeEventListener("resize", handleContainerHeightChange);
	}, [containerRef.current, containerHeight]);

	return (
		<>
			<CoAppDataGridHeader
				title="Trash"
				resourceType="Rule"
				addIsPresent={false}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppDataGridContainer ref={containerRef} height={containerHeight}>
				<CoAppDataGrid
					rows={rules}
					columns={filterableColumns}
					checkboxSelection
					pagination
					disableRowSelectionOnClick
					initialState={{
						...datagridInitialState,
						pinnedColumns: { left: ["delete", "restore", "__check__", "name"] }
					}}
					rowSelectionModel={selectionModel}
					onRowSelectionModelChange={handleRowSelectionChange}
					slots={{
						toolbar: CustomToolbar,
						noResultsOverlay: DataGridCustomNoRowsOverlay,
						noRowsOverlay: DataGridCustomNoRowsOverlay,
						openFilterButtonIcon: FilterAlt
					}}
					slotProps={{
						noResultsOverlay: { message: "No rules found." },
						noRowsOverlay: { message: "No rules found." }
					}}
					hideFooter
					disableColumnMenu
					sortModel={sortModel}
					onSortModelChange={handleSortModelChange}
				/>
			</CoAppDataGridContainer>
			<CoAppDestructiveConfirmationModal
				dialogOpen={destructiveModalOpen}
				dialogTitle={`Permanently delete ${ruleBeingDeleted.name} rule?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(ruleBeingDeleted.name, "rule")}
				confirmClickHandler={handleDeleteRule}
				cancelClickHandler={handleDeleteRuleToggle}
				actionText="Permanently delete"
			/>

			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkDestructiveModalOpen}
				dialogTitle="Permanently delete selected rules?"
				dialogMessage={messages.BULK_PERMANENTLY_DELETE_MSG("rules")}
				confirmClickHandler={handleBulkRuleDeletion}
				cancelClickHandler={() => setBulkDestructiveModalOpen(false)}
				actionText="Permanently delete"
			/>
			<RuleUndoSnackbar
				level={snackbarLevel}
				message={snackbarMessage}
				open={snackbarOpen}
				setLevel={setSnackbarLevel}
				setMessage={setSnackbarMessage}
				setOpen={setSnackbarOpen}
				undoHandler={isBulkUndo ? handleUndoBulkRestoration : handleUndoRestoration}
			/>
		</>
	);
}