import { isNumericId } from "./idValidation";

export const validateRoleId = (roleId) => {
	return isNumericId(roleId);
};

const roleValidation = {
	validateRoleId
};

export default roleValidation;