import { useEffect, useRef,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate, useParams } from "react-router-dom";
import { useDescope } from "@descope/react-sdk";
import { Delete } from "@mui/icons-material";
import {
	FormControlLabel, Grid2, Skeleton,
	Stack, Switch, Typography
} from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import pages from "../../../constants/pages";
import prettifyDate from "../../../helpers/prettifyDate";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useAuthorization from "../../../hooks/useAuthorization";
import useToastAlert from "../../../hooks/useToastAlert";
import { resetNavigationState } from "../../../redux/navigationSlice";
import {
	selectOrganizationSsoAndScim, selectUser, setFirstName,
	setGroups, setLastName, setOrgId,
	setPermissions, setPhone, setRoles,
	setUserId
} from "../../../redux/userSlice";
import CoAppIconTextButton from "../../global/components/CoAppIconTextButton";
import CoAppMultiSelect from "../../global/components/inputs/CoAppMultiSelect";
import CoAppTextField from "../../global/components/inputs/CoAppTextField";
import CoAppConfirmationModal from "../../global/components/modals/CoAppConfirmationModal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/CoAppDestructiveConfirmationModal";
import Unauthorized from "../../global/components/Unauthorized";
import { CoAppActionButton, CoAppCancelTextButton, CoAppProfileAvatar } from "../../global/styled/global.styled";

import { UserProfileNameText } from "./styled/user-profile.styled";

const initialUserDetails = {
	onLoadDetails: {
		active: null,
		firstName: "",
		lastName: "",
		roles: [],
		groups: []
	},
	onEditDetails: {
		active: null,
		firstName: "",
		lastName: "",
		roles: [],
		groups: []
	}
};
export default function UserProfile() {
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const isAuthorized = useAuthorization(["MANAGE_USERS_AND_GROUPS"]);
	const isSsoAndScimEnabled = useSelector(selectOrganizationSsoAndScim);
	const user = useSelector(selectUser);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { logout } = useDescope();
	const dataFetchedRef = useRef(false);

	const [isEditing, setIsEditing] = useState(false);
	const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const [availableGroups, setAvailableGroups] = useState([]);
	const [availableRoles, setAvailableRoles] = useState([]);
	const [email, setEmail] = useState("");
	const [lastHeartbeat, setLastHeartbeat] = useState("");
	const [userCreationDate, setUserCreationDate] = useState("");
	const [userDetails, setUserDetails] = useState(initialUserDetails);
	const [userUpdateSeed, setUserUpdateSeed] = useState(0);
	const [loading, setLoading] = useState(true);

	// This function is deprecated in favor of the useEffect approach
	// It's kept for backward compatibility only
	const initUserProfile = () => {
		console.warn("initUserProfile is being called directly which may cause infinite loops");
		
		// Safety checks to prevent infinite loops
		if (!user || user.userId === undefined || !id || !authHeader) {
			console.warn("initUserProfile called with missing data");
			return;
		}
		
		try {
			const isSelf = user.userId === +id ? 1 : 0;
			
			// Use a unique timestamp to prevent caching issues
			const timestamp = new Date().getTime();
			axios.get(`${apiRoutes.getUser(isSelf, id, "")}?_t=${timestamp}`, { headers: authHeader })
				.then(response => {
					if (!response || !response.data) return;
					
					let details = {
						onLoadDetails: {
							active: response.data.active,
							firstName: response.data.firstname,
							lastName: response.data.lastname,
							roles: response.data.roles || [],
							groups: response.data.groups ? response.data.groups.map(g => g.name) : [],
						},
						onEditDetails: {
							active: response.data.active,
							firstName: response.data.firstname,
							lastName: response.data.lastname,
							roles: response.data.roles || [],
							groups: response.data.groups ? response.data.groups.map(g => g.name) : [],
						}
					};
					setUserDetails(details);
					setEmail(response.data.email || "");
					setLoading(false);
					
					if (response.data.lastHeartbeat !== undefined) {
						setLastHeartbeat(response.data.lastHeartbeat === null ? "Never" : prettifyDate(response.data.lastHeartbeat));
					}
					
					if (response.data.createdAt) {
						setUserCreationDate(prettifyDate(response.data.createdAt));
					}
				})
				.catch(err => {
					console.log(err);
					setLoading(false);
				});
		} catch (err) {
			console.error("Error in initUserProfile:", err);
			setLoading(false);
		}
	};

	// This function is kept for backward compatibility
	const initRolesAndGroupsForEditing = () => {
		console.warn("initRolesAndGroupsForEditing called directly - consider using the useEffect pattern");
		
		if (!authHeader) {
			console.warn("initRolesAndGroupsForEditing called without authHeader");
			return;
		}
		
		// Add a timestamp to prevent caching issues
		const timestamp = new Date().getTime();
		
		try {
			// Use Promise.all to run these in parallel
			Promise.all([
				axios.get(`${apiRoutes.getGroups}?_t=${timestamp}`, { headers: authHeader }),
				axios.get(`${apiRoutes.getRoles}?_t=${timestamp}`, { headers: authHeader })
			])
				.then(([groupsRes, rolesRes]) => {
					if (groupsRes && groupsRes.data) {
						setAvailableGroups(groupsRes.data);
					}
				
					if (rolesRes && rolesRes.data) {
						setAvailableRoles(rolesRes.data);
					}
				})
				.catch(err => {
					console.log("Error fetching roles/groups:", err);
				});
		} catch (err) {
			console.error("Error in initRolesAndGroupsForEditing:", err);
		}
	};

	/**
	 * User confirms account de-activation
	 * - Deactive user
	 * - If currently logged in user, log them out, redirect to login page.
	 * - If not currently logged in user, just de-activate user. 
	 */
	const handleDeactivateAccountClick = () => {
		let userJSON = {
			active: userDetails.onLoadDetails.active ? false : true
		};
		axios.put(apiRoutes.setUserActive(id), userJSON, {
			headers: authHeader
		}).then(() => {
			if (user.userId === +id) {
				logout();
				dispatch(setUserId(null));
				dispatch(setEmail(null));
				dispatch(setFirstName(null));
				dispatch(setLastName(null));
				dispatch(setPhone(null));
				dispatch(setRoles(null));
				dispatch(setOrgId(null));
				dispatch(resetNavigationState());
				navigate(pages.login);
			} else {
				initUserProfile();
				handleToggleDisableDialog();
			}
		}).catch((err) => {
			console.log(err);
			handleUpdateUserAlert(messageLevels.ERROR);
		});
	};

	const handleDeleteAccount = () => {
		axios.delete(apiRoutes.deleteUser + "/" + id, {
			headers: authHeader
		}).then(() => {
			if (user.userId === +id) {
				logout();
				dispatch(setUserId(null));
				dispatch(setEmail(null));
				dispatch(setFirstName(null));
				dispatch(setLastName(null));
				dispatch(setPhone(null));
				dispatch(setRoles(null));
				dispatch(setOrgId(null));
				dispatch(resetNavigationState());
				navigate(pages.login);
			} else {
				handleToggleDeleteDialog();
				handleToastAlert(messageLevels.INFO, messages.USER_DELETION_SUCCESS_MSG);
			}
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.USER_DELETION_ERROR_MSG);
		});
	};

	const getAvatarText = () => {
		if (!email) return null;
		if (userDetails.onLoadDetails.firstName && userDetails.onLoadDetails.lastName) {
			return userDetails.onLoadDetails.firstName[0].toUpperCase() + userDetails.onLoadDetails.lastName[0].toUpperCase();
		} else {
			return email[0].toUpperCase();
		}
	};

	const handleSaveChangesClick = () => {
		let userJSON = {};
		const { onLoadDetails, onEditDetails } = userDetails;

		if (onLoadDetails.firstName !== onEditDetails.firstName) {
			userJSON.firstName = onEditDetails.firstName;
		}
		if (onLoadDetails.lastName !== onEditDetails.lastName) {
			userJSON.lastName = onEditDetails.lastName;
		}
		if (JSON.stringify(onLoadDetails.roles) !== JSON.stringify(onEditDetails.roles)) {
			userJSON.roles = onEditDetails.roles;
		}
		if (JSON.stringify(onLoadDetails.groups) !== JSON.stringify(onEditDetails.groups)) {
			userJSON.groups = onEditDetails.groups;
		}

		if (Object.keys(userJSON).length > 0) {
			axios.put(apiRoutes.updateUser + "/" + id, userJSON, {
				headers: authHeader
			}).then((res) => {
				if (user.userId === +id) {
					dispatch(setFirstName(res.data.firstname));
					dispatch(setLastName(res.data.lastname));
					dispatch(setGroups(res.data.groups));
					dispatch(setRoles(res.data.roles));
					dispatch(setPermissions(res.data.permissions));
				}
				setIsEditing(false);
			}).catch((err) => {
				handleUpdateUserAlert(messageLevels.ERROR);
				setUserDetails({ ...userDetails, onEditDetails: userDetails.onLoadDetails });
				console.log(err);
				return null;
			});
		}
	};

	const handleUpdateUserAlert = (level) => {
		setUserUpdateSeed(Math.random());
		setIsEditing(false);
		handleToastAlert(level, level === "success" ? messages.USER_UPDATE_SUCCESS_MSG : messages.USER_UPDATE_ERROR_MSG);
	};

	const handleToggleDisableDialog = () => {
		setIsDisableDialogOpen(!isDisableDialogOpen);
	};

	const handleToggleDeleteDialog = () => {
		setIsDeleteDialogOpen(!isDeleteDialogOpen);
	};

	//Sort group and role arrays so the stringification comparison behaves as expected
	//Dress up a new object so we are not mutating the original array when we sort
	const userDetailsHaveChanged = (details) => {
		let temp = {
			...details,
			onEditDetails: {
				...details.onEditDetails,
				roles: [...details.onEditDetails.roles].sort(),
				groups: [...details.onEditDetails.groups].sort()
			},
			onLoadDetails: {
				...details.onLoadDetails,
				roles: [...details.onLoadDetails.roles].sort(),
				groups: [...details.onLoadDetails.groups].sort()
			}
		};
		return JSON.stringify(temp.onLoadDetails) !== JSON.stringify(temp.onEditDetails);
	};

	const handleMultiselectChange = (e, value, reason, field) => {
		const newDetails = { ...userDetails, onEditDetails: { ...userDetails.onEditDetails, [field]: value } };
		if (userDetailsHaveChanged(newDetails)) {
			setIsEditing(true);
		} else {
			setIsEditing(false);
		}
		setUserDetails(newDetails);
	};

	const handleValueChange = (e, field) => {
		const newDetails = { ...userDetails, onEditDetails: { ...userDetails.onEditDetails, [e.target.id !== undefined ? e.target.id : field]: e.target.value } };
		if (userDetailsHaveChanged(newDetails)) {
			setIsEditing(true);
		} else {
			setIsEditing(false);
		}
		setUserDetails(newDetails);
	};

	const handleCancelButtonClick = () => {
		setUserDetails({ ...userDetails, onEditDetails: userDetails.onLoadDetails });
		setIsEditing(false);
	};

	useEffect(() => {
		// Skip if not authorized
		if (!isAuthorized) {
			return;
		}
		
		// Store values in a ref to avoid triggering effect on every change
		const controller = new AbortController();
		
		// If we're missing critical data, don't proceed
		if (!id || !authHeader || Object.keys(authHeader).length === 0) {
			return;
		}
		
		// This function handles fetching user data
		const fetchUserProfile = async () => {
			try {
				// Always use a safe value for isSelf
				const isSelf = user && user.userId ? (user.userId === +id ? 1 : 0) : 0;
				
				const response = await axios.get(
					apiRoutes.getUser(isSelf, id, ""), 
					{ 
						headers: authHeader,
						signal: controller.signal 
					}
				);
				
				if (response.data) {
					// Create a safe copy of the response data
					const safeResponse = {
						active: response.data.active || false,
						firstname: response.data.firstname || "",
						lastname: response.data.lastname || "",
						roles: response.data.roles || [],
						groups: response.data.groups || [],
						email: response.data.email || "",
						lastHeartbeat: response.data.lastHeartbeat,
						createdAt: response.data.createdAt
					};
					
					let details = {
						onLoadDetails: {
							active: safeResponse.active,
							firstName: safeResponse.firstname,
							lastName: safeResponse.lastname,
							roles: safeResponse.roles,
							groups: safeResponse.groups.map ? safeResponse.groups.map(g => g.name) : [],
						},
						onEditDetails: {
							active: safeResponse.active,
							firstName: safeResponse.firstname,
							lastName: safeResponse.lastname,
							roles: safeResponse.roles,
							groups: safeResponse.groups.map ? safeResponse.groups.map(g => g.name) : [],
						}
					};
					
					// Update all state in a single batch if possible
					setUserDetails(details);
					setEmail(safeResponse.email);
					setLoading(false);
					setLastHeartbeat(safeResponse.lastHeartbeat === null ? "Never" : prettifyDate(safeResponse.lastHeartbeat));
					setUserCreationDate(prettifyDate(safeResponse.createdAt));

					// Only fetch roles and groups if we successfully loaded the user
					fetchRolesAndGroups();
				}
			} catch (err) {
				if (!axios.isCancel(err)) {
					console.log(err);
					setLoading(false);
				}
			}
		};
		
		// This function handles fetching related data
		const fetchRolesAndGroups = async () => {
			try {
				const [groupsResponse, rolesResponse] = await Promise.all([
					axios.get(apiRoutes.getGroups, { 
						headers: authHeader,
						signal: controller.signal 
					}),
					axios.get(apiRoutes.getRoles, { 
						headers: authHeader,
						signal: controller.signal 
					})
				]);
				
				if (groupsResponse.data) {
					setAvailableGroups(groupsResponse.data);
				}
				
				if (rolesResponse.data) {
					setAvailableRoles(rolesResponse.data);
				}
			} catch (err) {
				if (!axios.isCancel(err)) {
					console.log(err);
				}
			}
		};
		
		// Start the fetch process
		fetchUserProfile();
		
		// Cleanup function to abort requests when component unmounts or effect reruns
		return () => {
			controller.abort();
		};
	}, [id, userUpdateSeed, isAuthorized, authHeader, user]);

	if (isAuthorized) {
		return (
			<Grid2 container sx={{ paddingBottom: "10px" }}>
				<Grid2 container justifyContent="space-between">
					<Stack direction="row" spacing={1} sx={{ margin: "25px" }}>
						{
							loading ?
								<>
									<Skeleton variant="circular" width={60} height={60} />
									<Skeleton variant="rectangular" width={200} height={60} sx={{ borderRadius: "10px" }} />
								</>
								:
								<>
									<CoAppProfileAvatar h="60px" w="60px" fs="28px">
										{getAvatarText()}
									</CoAppProfileAvatar>
									<UserProfileNameText>
										{userDetails.onLoadDetails.firstName && userDetails.onLoadDetails.lastName ? `${userDetails.onLoadDetails.firstName} ${userDetails.onLoadDetails.lastName}` : null}
									</UserProfileNameText>
								</>
						}
					</Stack>
					{
						loading ?
							<Skeleton variant="rectangular" width={125} height={30} sx={{ borderRadius: "10px", margin: "40px 20px 20px 10px" }} />
							:
							<FormControlLabel
								sx={{ marginRight: "10px" }}
								labelPlacement="start"
								label={<Typography variant="body1">{userDetails.onLoadDetails.active ? "Active" : "Inactive"}</Typography>}
								control={
									<Switch
										checked={userDetails.onLoadDetails.active}
										onClick={handleToggleDisableDialog}
										disabled={!isAuthorized || isSsoAndScimEnabled}
									/>
								}
							/>
					}
				</Grid2>
				<Grid2 container sx={{ marginLeft: "30px" }}>
					<Grid2 item xxl={2.5} xl={3} lg={2.7} md={3}>
						{
							loading ?
								<Skeleton variant="rectangular" height={40} sx={{ borderRadius: "10px", marginTop: "30px", width: { xxl: "250px", xl: "220px", lg: "170px", md: "150px" } }} />
								:
								<CoAppTextField
									sx={{ width: { xxl: "250px", xl: "220px", lg: "170px", md: "150px" } }}
									id="firstName"
									label="First Name"
									onChange={handleValueChange}
									value={userDetails.onEditDetails.firstName}
									variant="standard"
									disabled={!isAuthorized || isSsoAndScimEnabled}
								/>
						}
					</Grid2>
					<Grid2 item xxl={4} xl={3.5} lg={3.8} md={3.5}>
						{
							loading ?
								<Skeleton variant="rectangular" height={40} sx={{ borderRadius: "10px", marginTop: "30px", width: { xxl: "250px", xl: "220px", lg: "170px", md: "150px" } }} />
								:
								<CoAppTextField
									sx={{ width: { xxl: "250px", xl: "220px", lg: "170px", md: "150px" } }}
									id="lastName"
									variant="standard"
									label="Last Name"
									value={userDetails.onEditDetails.lastName}
									onChange={handleValueChange}
									disabled={!isAuthorized || isSsoAndScimEnabled}
								/>
						}
					</Grid2>
					<Grid2 item xl={5} md={5}>
						{
							loading ?
								<Skeleton variant="rectangular" height={40} sx={{ borderRadius: "10px", marginTop: "30px", width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }} />
								:
								<CoAppTextField
									sx={{ width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }}
									disabled={true}
									id="email"
									label="Email"
									isemail="true"
									value={email}
									variant="standard"
								/>
						}
					</Grid2>
				</Grid2>
				<Grid2 container sx={{ marginLeft: "30px", marginTop: "50px" }} alignItems="flex-end">
					<Grid2 item xs={6.5}>
						{
							loading ?
								<Skeleton variant="rectangular" height={40} sx={{ borderRadius: "10px", marginTop: "30px", width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }} />
								:
								<CoAppMultiSelect
									chipSize="small"
									customStyles={{ width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }}
									disabled={!isAuthorized || isSsoAndScimEnabled}
									label="Roles"
									options={availableRoles.map(role => role.name)}
									value={userDetails.onEditDetails.roles}
									onChange={(e, value, reason) => handleMultiselectChange(e, value, reason, "roles")}
								/>
						}
					</Grid2>
					<Grid2 item xs={5.5}>
						{
							loading ?
								<Skeleton variant="rectangular" height={40} sx={{ borderRadius: "10px", marginTop: "30px", width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }} />
								:
								<CoAppMultiSelect
									chipSize="small"
									customStyles={{ width: { xxl: "565px", xl: "565px", lg: "420px", md: "380px" } }}
									disabled={!isAuthorized || isSsoAndScimEnabled}
									label="Groups"
									options={availableGroups.map(role => role.name)}
									value={userDetails.onEditDetails.groups}
									onChange={(e, value, reason) => handleMultiselectChange(e, value, reason, "groups")}
								/>
						}
					</Grid2>
				</Grid2>
				<Grid2 container sx={{ marginLeft: "20px", marginTop: "50px", position: "relative" }} wrap="nowrap">
					<Grid2 item xs={3.5} md={2.5} xxl={4} xl={4}>
						{
							loading ?
								<Skeleton variant="rectangular" height={30} sx={{ borderRadius: "10px", marginTop: "10px", width: { xxl: "200px", xl: "170px", lg: "120px", md: "100px" } }} />
								:
								<CoAppIconTextButton
									icon={<Delete fontSize="small" sx={{ marginRight: "5px" }} />}
									text="Delete account"
									onClick={handleToggleDeleteDialog}
									isDestructive={true}
								/>
						}
					</Grid2>
					<Grid2 item xs={8} md sx={{ marginTop: "10px" }}>
						<Stack direction="row" spacing={3}>
							{
								loading ?
									<>
										<Skeleton variant="rectangular" height={30} sx={{ borderRadius: "10px", width: { xxl: "250px", xl: "220px", lg: "170px", md: "150px" } }} />
										<Skeleton variant="rectangular" height={30} sx={{ borderRadius: "10px", width: { xxl: "200px", xl: "170px", lg: "120px", md: "100px" } }} />
									</>
									:
									<>
										<Typography variant="body2" color="text.secondary">Last heartbeat: {lastHeartbeat.toString().replace(",", " at ")}</Typography>
										<Typography variant="body2" color="text.secondary">Created: {userCreationDate.toString().replace(",", " at ")}</Typography>
									</>
							}
						</Stack>
					</Grid2>
					<Grid2 sx={{ display: isEditing ? "flex" : "none", position: "absolute", right: 20 }}>
						<CoAppCancelTextButton onClick={handleCancelButtonClick}>Cancel</CoAppCancelTextButton>
						<CoAppActionButton onClick={handleSaveChangesClick}>Save</CoAppActionButton>
					</Grid2>
				</Grid2>
				<CoAppDestructiveConfirmationModal
					dialogOpen={isDisableDialogOpen && userDetails.onLoadDetails.active}
					dialogMessage={`Are you sure you want to disable ${email}? The user will no longer be able to log in once disabled.`}
					dialogTitle={`Disable ${email}`}
					confirmClickHandler={handleDeactivateAccountClick}
					cancelClickHandler={handleToggleDisableDialog}
					actionText="Disable"
				/>
				<CoAppConfirmationModal
					dialogOpen={isDisableDialogOpen && !userDetails.onLoadDetails.active}
					dialogMessage={`Are you sure you want to enable ${email}? The user will be able to log in once enabled.`}
					dialogTitle={`Enable ${email}`}
					confirmClickHandler={handleDeactivateAccountClick}
					cancelClickHandler={handleToggleDisableDialog}
					actionText="Enable"
				/>
				<CoAppDestructiveConfirmationModal
					dialogOpen={isDeleteDialogOpen}
					dialogMessage={`Are you sure you want to delete ${email}? The user will no longer exist in your organization once deleted.`}
					dialogTitle={`Delete ${email}`}
					confirmClickHandler={handleDeleteAccount}
					cancelClickHandler={handleToggleDeleteDialog}
					actionText="Delete"
				/>
			</Grid2>
		);
	} else {
		return (
			<Unauthorized />
		);
	}
}