import { Card, CircularProgress } from "@mui/material";

import { CoAppBasicContainer, CoAppH2 } from "../global/styled/global.styled";

/**
 * Reusable loading card component for the entire application
 * 
 * @param {Object} props
 * @param {string} props.title - Loading title
 * @param {string} props.message - Loading message
 * @param {string} props.subMessage - Secondary loading message
 */
const LoadingCard = ({
	title = "Loading",
	message = "Please wait...",
	subMessage = ""
}) => {
	return (
		<CoAppBasicContainer>
			<Card
				sx={{
					alignItems: "center",
					border: "none",
					borderRadius: "0px",
					boxShadow: "0px 1.97px 20px -0.99px #00000021",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					height: "497px",
					width: { xxl: "1364px", xl: "1100px", lg: "900px", md: "700px", sm: "90%", xs: "95%" },
					maxWidth: "1364px", 
					marginTop: "50px",
					marginLeft: "auto",
					marginRight: "auto",
					padding: "20px"
				}}
			>
				<CircularProgress size={50} color="primary" />
				<CoAppH2 style={{ marginTop: "20px" }}>{title}</CoAppH2>
				<p style={{ margin: "10px 0" }}>{message}</p>
				{subMessage && (
					<p style={{ margin: "5px 0", color: "#666" }}>
						{subMessage}
					</p>
				)}
			</Card>
		</CoAppBasicContainer>
	);
};

export default LoadingCard;