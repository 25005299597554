import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { CoAppActionButton, CoAppCancelTextButton, CoAppCloseIcon, CoAppDialog } from "../../styled/global.styled";
import CoAppTextField from "../inputs/CoAppTextField";

export default function CoAppCreateGroupOrRoleModal(props) {
	const {
		dialogOpen, changeHandler,
		confirmClickHandler, cancelClickHandler, nameError,
		isRole = false
	} = props;
	return (
		<CoAppDialog
			open={dialogOpen}
			onClose={cancelClickHandler}
			slotProps={{
				paper: {
					sx: {
						borderRadius: "8px",
						padding: "8px",
						width: "400px"
					}
				}
			}}		
		>
			<div>
				<DialogTitle variant="h6">{`Add ${isRole ? "role" : "group"}`}</DialogTitle>
				<CoAppCloseIcon onClick={cancelClickHandler} top={10} left={365} />
			</div>
			<DialogContentText
				variant="body2"
				color="text.primary"
				sx={{ marginLeft: "25px", width: "250px" }}
			>
				{`Create a new ${isRole ? "role" : "group"} for users in your organization.`}
			</DialogContentText>
			<DialogContent>
				<CoAppTextField
					FormHelperTextProps={{ sx: { color: "#DE3730" } }}
					onChange={changeHandler}
					placeholder={`${isRole ? "Role" : "Group"} name`}
					fullWidth={true}
					error={nameError !== ""}
					helperText={nameError === "" ? "Required *" : nameError}
					required={true}
				/>
			</DialogContent>
			<DialogActions sx={{ marginBottom: "15px", marginRight: "12px" }}>
				<CoAppCancelTextButton onClick={cancelClickHandler}>Cancel</CoAppCancelTextButton>
				<CoAppActionButton onClick={confirmClickHandler}>Save</CoAppActionButton>
			</DialogActions>
		</CoAppDialog>
	);
}